import { createPartner, fetchPartner, updatePartner } from "@/modules/partners/services/partner.services"
import { Partner } from "@/modules/partners/types"
import { notificationAction, notificationCreateSuccess, notificationUpdateSuccess } from "@/utils"
import { NotificationEnum, StatusEnum } from "@/utils/constants"
import { Button, Form, Input, Modal, Select, Space } from "antd"
import { useEffect, useState } from "react"

interface BaseUpsertForm {
  openProps?: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: string
  idEdit?: string
}

const UpsertForm: React.FC<BaseUpsertForm> = props => {
  const [form] = Form.useForm()
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const onReset = () => {
    form.resetFields()
  }

  const handleCompleteForm = () => {
    onReset()
    props.refreshProps()
    props.cancelProps()
    props.idEdit ? notificationUpdateSuccess() : notificationCreateSuccess()
    setButtonLoading(false)
  }

  const handleAddPartner = async () => {
    try {
      const values = await form.validateFields()
      const newData: Partner = {
        ...values,
      }
      setButtonLoading(true)
      const response = await createPartner(newData)
      if (response.statusCode === 200) {
        handleCompleteForm()
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Addition failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  useEffect(() => {
    if (props.actionType === "CREATE") {
      form.resetFields()
    }

    const onInitEdit = async (idEdit: string) => {
      const partner = await fetchPartner(idEdit)

      form.setFieldsValue({
        name: partner.data.name,
        status: partner.data.status,
        phone_number: partner.data.phone_number,
      })
    }

    if (props.idEdit) {
      onInitEdit(props.idEdit)
    }
  }, [props.actionType, props.idEdit])

  const handleUpdatePartner = async () => {
    try {
      if (props.idEdit) {
        const values = await form.validateFields()
        const editData = {
          ...values,
        }
        setButtonLoading(true)
        const response = await updatePartner(props.idEdit, editData)
        if (response.statusCode === 200) {
          handleCompleteForm()
        }
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Edit failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button
            key="cancel"
            onClick={() => {
              props.cancelProps()
              onReset()
            }}
          >
            Hủy
          </Button>
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={handleUpdatePartner} loading={buttonLoading}>
              Lưu
            </Button>
          )}
          {props.actionType === "CREATE" && (
            <Button type="primary" onClick={() => handleAddPartner()} loading={buttonLoading}>
              Thêm mới
            </Button>
          )}
        </Space>
      </>
    )
  }
  return (
    <Modal
      title={props.actionType === "CREATE" ? "Thêm đối tác" : "Quản lí đối tác"}
      open={props.openProps}
      width={600}
      onCancel={props.cancelProps}
      footer={renderFooter()}
    >
      <Form
        name="wrap"
        labelCol={{ flex: "110px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        style={{ maxWidth: 600 }}
        form={form}
      >
        <Form.Item label="Tên đối tác" name="name" rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}>
          <Input placeholder="Nhập tên đối tác" />
        </Form.Item>
        <Form.Item
          label="SĐT:"
          name="phone_number"
          rules={[{ required: true, pattern: new RegExp(/^\d*(\.\d+)?$/), message: "Vui lòng nhập dữ liệu số vào!" }]}
        >
          <Input placeholder="Nhập số điện thoại" />
        </Form.Item>
        <Form.Item label="Trạng thái" name="status" initialValue={StatusEnum.ACTIVE}>
          <Select
            placeholder="Vui lòng chọn trạng thái"
            options={[
              { value: StatusEnum.ACTIVE, label: "Hoạt động" },
              { value: StatusEnum.DISABLED, label: "Không hoạt động" },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default UpsertForm
