import CheckinForm from "@/modules/hrs/check_ins/views/CheckIn"
import { useGoBack } from "@/utils/use-go-back"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

export const Upsert = () => {
  const { id } = useParams()

  const [isUpdate, setUpdate] = useState<boolean>(false)

  useEffect(() => {
    if (id) {
      setUpdate(true)
    }
  }, [])
  const { goBack } = useGoBack("/hrs/checkin")

  const onCancel = () => goBack()
  return (
    <>
      {/* <UpsertForm
        key="UPDATE"
        cancelProps={onCancel}
        openProps={isUpdate}
        refreshProps={onRefreshProps}
        actionType="UPDATE"
        idEdit={id}
      /> */}

      <CheckinForm cancelProps={onCancel} openProps={isUpdate} id={id} />
    </>
  )
}
