import { profile } from "@/redux/reducers/profile"
import { combineReducers } from "redux"
import { role as action } from "./actions"
import { auth } from "./auth"
import { common } from "./common"

export default combineReducers({
  common,
  action,
  auth,
  profile,
})
