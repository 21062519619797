import { Http } from "@/network/http"

export const API_PREFIX_PATH = "/settings"

export const fetchSettings = async (name: string) => {
  return await Http.get(`${API_PREFIX_PATH}/${name}`)
}

export const updateSettings = async (data: { name: string; value: string }) => {
  return await Http.post(`${API_PREFIX_PATH}`, data)
}
