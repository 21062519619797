export enum PermissionsEnum {
  CREATE = "create",
  READ = "read",
  DELETE = "delete",
  UPDATE = "update",
  RESTORE = "restore",
}

export const MAP_PERMISSIONS = {
  [PermissionsEnum.CREATE]: "Tạo",
  [PermissionsEnum.READ]: "Xem",
  [PermissionsEnum.DELETE]: "Xóa",
  [PermissionsEnum.UPDATE]: "Sửa",
  [PermissionsEnum.RESTORE]: "Khôi phục",
}
