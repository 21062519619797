import { InternalDebt } from "@/modules/hrs/internal_debts"

export enum Gender {
  MALE = "male",
  FEMALE = "female",
  OTHER = "other",
}
export enum Checkin {
  FULLDAY = 1,
  HALFDAY = 0.5,
  OFF = 0,
}

export const MAP_CHECKIN = {
  [Checkin.FULLDAY]: "Full ngày",
  [Checkin.HALFDAY]: "Nửa ngày",
  [Checkin.OFF]: "Nghỉ",
}
export const MAP_GENDER = {
  [Gender.MALE]: "Nam",
  [Gender.FEMALE]: "Nữ",
  [Gender.OTHER]: "Khác",
}

export interface Staff {
  id?: string
  name: string
  birthday: Date
  phone_number?: string
  identification_id?: string
  hometown?: string
  sex?: string
  position_id: string
  basic_income: string
  created_at?: string
  updated_at?: string
  created_by_id?: number
  position?: Position
  created_by?: CreatedBy
  status: string
  internal_debts?: InternalDebt[]
}

export interface CreatedBy {
  id: string
  username: string
  password: string
  phone_number: string
  fullname: string
  role_id: number
  created_at: string
  updated_at: string
  status: string
}

export enum Position {
  MANAGER = "1",
  CHEF = "2",
  WAITER = "3",
  BARTENDER = "4",
  CASHIER = "5",
}

export const MAP_POSITION: Record<string, string> = {
  [Position.MANAGER]: "Quản lí",
  [Position.CHEF]: "Đầu bếp",
  [Position.WAITER]: "Phục vụ",
  [Position.BARTENDER]: "Pha chế",
  [Position.CASHIER]: "Thu ngân",
}

export interface CheckIn {
  staff_id: number
  value: CheckInValue[]
}

export interface CheckInValue {
  date: string
  value: number
}

export interface PositionStaffType {
  id: string
  name: string
}
