import { createCustomer, fetchCustomer, updateCustomer } from "@/modules/customers/services/customers.services"
import { Customer } from "@/modules/customers/types"
import { notificationAction, notificationCreateSuccess, notificationUpdateSuccess } from "@/utils"
import { NotificationEnum, StatusEnum } from "@/utils/constants"
import { Button, Form, Input, Modal, Space } from "antd"
import { useEffect, useState } from "react"

interface BaseUpsertForm {
  openProps?: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: string
  idEdit?: string
}

const UpsertForm: React.FC<BaseUpsertForm> = props => {
  const [form] = Form.useForm()
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const onReset = () => {
    form.resetFields()
  }

  const handleCompleteForm = () => {
    onReset()
    props.refreshProps()
    props.cancelProps()
    props.idEdit ? notificationUpdateSuccess() : notificationCreateSuccess()
    setButtonLoading(false)
  }

  const handleAddCustomer = async () => {
    try {
      const values = await form.validateFields()
      const newData: Customer = {
        ...values,
        status: StatusEnum.ACTIVE,
      }
      setButtonLoading(true)
      const response = await createCustomer(newData)
      if (response.statusCode === 200) {
        handleCompleteForm()
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Addition failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  useEffect(() => {
    if (props.actionType === "CREATE") {
      form.resetFields()
    }

    const onInitEdit = async (idEdit: string) => {
      const customer = await fetchCustomer(idEdit)

      form.setFieldsValue({
        name: customer.data.name,
        phone_number: customer.data.phone_number,
      })
    }

    if (props.idEdit) {
      onInitEdit(props.idEdit)
    }
  }, [props.actionType, props.idEdit])

  const handleUpdateCustomer = async () => {
    try {
      if (props.idEdit) {
        const values = await form.validateFields()
        const editData = {
          ...values,
          status: StatusEnum.ACTIVE,
        }
        setButtonLoading(true)
        const response = await updateCustomer(props.idEdit, editData)
        if (response.statusCode === 200) {
          handleCompleteForm()
        }
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Edit failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button
            key="cancel"
            onClick={() => {
              props.cancelProps()
              onReset()
            }}
          >
            Hủy
          </Button>
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={handleUpdateCustomer} loading={buttonLoading}>
              Lưu
            </Button>
          )}
          {props.actionType === "CREATE" && (
            <Button type="primary" onClick={() => handleAddCustomer()} loading={buttonLoading}>
              Thêm mới
            </Button>
          )}
        </Space>
      </>
    )
  }
  return (
    <Modal
      title={props.actionType === "CREATE" ? "Thêm khách hàng" : "Chỉnh sửa khách hàng"}
      open={props.openProps}
      width={600}
      onCancel={props.cancelProps}
      footer={renderFooter()}
    >
      <Form
        name="wrap"
        labelCol={{ flex: "140px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        style={{ maxWidth: 600 }}
        form={form}
      >
        <Form.Item
          label="Tên khách hàng"
          name="name"
          rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
        >
          <Input placeholder="Nhập tên khách hàng" />
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          name="phone_number"
          rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
        >
          <Input placeholder="Nhập tên số điện thoại" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default UpsertForm
