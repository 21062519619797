import { Staff } from "@/modules/hrs/staffs/types"
import { Http } from "@/network/http"
import { notificationAction } from "@/utils"
import { Filters, NotificationEnum, StatusEnum } from "@/utils/constants"

export const API_PREFIX_PATH = "/staffs"
export const fetchStaffs = async (filters?: Filters) => {
  return await Http.get(`${API_PREFIX_PATH}`, { params: filters })
}

export const fetchStaff = async (id: string) => {
  return await Http.get(`${API_PREFIX_PATH}/${id}`)
}

export const createStaff = async (data?: Staff) => {
  return await Http.post(`${API_PREFIX_PATH}`, data)
}

export const updateStaff = async (id: string, data?: Staff) => {
  return await Http.put(`${API_PREFIX_PATH}/${id}`, data)
}

export const deleteStaff = async (data: Staff) => {
  try {
    const updatedData = { ...data }
    if (!updatedData.id) return
    const response = await updateStaff(updatedData.id, { ...updatedData, status: StatusEnum.DELETED })
    if (response.statusCode === 200) {
      notificationAction(NotificationEnum.DELETED, NotificationEnum.SUCCESS)
    }
  } catch (error) {
    console.error("Addition failed:", error)
    notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
  }
}
