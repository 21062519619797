import View from "@/modules/finances/currency/view/List"
import { Route, Routes } from "react-router-dom"

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<View />} />
    </Routes>
  )
}
