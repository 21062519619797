import { applyMiddleware, compose, createStore } from "redux"

import { createLogger } from "redux-logger"
import reducers from "./reducers"

const middleware = []

if (import.meta.env.NODE_ENV === "development") {
  middleware.push(createLogger())
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// @ts-ignore
const enhancers = composeEnhancers(applyMiddleware(...middleware))

const store = createStore(reducers, enhancers)

export const dispatch = store.dispatch
export const getState = store.getState

export type RootState = ReturnType<typeof reducers>
export default store
