import PermissionList from "@/modules/manages/roles/view/PermissionsList"
import { createUpdateRole, fetchRole } from "@/modules/manages/users/services/roles.services"
import { Button, Form, Input, Modal, Space } from "antd"
import { useEffect, useState } from "react"

interface BaseUpsertForm {
  openProps?: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: string
  idEdit?: string
}

const UpsertForm: React.FC<BaseUpsertForm> = props => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [rolePermissions, setRolePermissions] = useState<number[]>([])
  const onReset = () => {
    form.resetFields()
  }

  const handlePermissionsChecked = (checked: boolean, value: number) => {
    if (checked) {
      setRolePermissions([...rolePermissions, value])
    } else {
      setRolePermissions([...rolePermissions.filter((permission: number) => permission !== value)])
    }
  }

  useEffect(() => {
    if (props.actionType === "UPDATE" && props.idEdit) {
      fetchRole(props.idEdit).then(res => {
        form.setFieldsValue({
          name: res.data.name,
        })
        if (res.data.permissions.length) {
          setRolePermissions(res.data.permissions)
        }
      })
    }
    if (props.actionType === "CREATE") {
      form.resetFields()
    }
  }, [props.idEdit])

  const handleRole = async (id?: string) => {
    try {
      const values = await form.validateFields()
      const data = {
        name: values.name,
        permission_ids: rolePermissions,
      }
      setLoading(true)
      const response = await createUpdateRole(data, id)

      if (response.statusCode === 200) {
        setLoading(false)
        onReset()
        props.cancelProps()
        props.refreshProps()
      } else {
        setLoading(false)
        form.setFields([
          {
            name: "name",
            errors: [response.message],
          },
        ])
      }

      return Promise.resolve()
    } catch (error) {
      console.error("Edit failed:", error)
    }
  }

  const renderFooter = (id?: string) => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button
            key="cancel"
            onClick={() => {
              props.cancelProps()
              onReset()
            }}
          >
            Hủy
          </Button>
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={() => handleRole(id)} loading={loading}>
              Lưu
            </Button>
          )}
          {props.actionType === "CREATE" && (
            <Button type="primary" onClick={() => handleRole()} loading={loading}>
              Thêm mới
            </Button>
          )}
        </Space>
      </>
    )
  }
  return (
    <Modal
      title={props.actionType === "CREATE" ? "Thêm vai trò" : "Chỉnh sửa vai trò"}
      open={props.openProps}
      width={600}
      onCancel={props.cancelProps}
      footer={renderFooter(props.idEdit)}
      forceRender
    >
      <Form
        name="wrap"
        labelCol={{ flex: "160px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        style={{ maxWidth: 600 }}
        form={form}
      >
        <Form.Item label="Tên:" name="name" rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}>
          <Input placeholder="Nhập tên của vai trò" />
        </Form.Item>
      </Form>
      <PermissionList data={rolePermissions} handleCheck={handlePermissionsChecked} />
    </Modal>
  )
}
export default UpsertForm
