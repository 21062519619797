import { CurrencyTypes } from "@/modules/finances/currency/types"
import { MoneyFormItem } from "@/modules/finances/currency/view/MoneyFormItem"
import { formatMoney } from "@/utils/helpers"
import { ReloadOutlined } from "@ant-design/icons"
import { Button, Card, Divider, Flex, Form, InputNumber, List, Row, Space, Typography } from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useLocalStorage } from "usehooks-ts"
const View: React.FC = () => {
  const [totalMoney, setTotalMoney] = useLocalStorage("totalMoney", 0)
  const [form] = Form.useForm()
  const [isResetForm, setIsResetForm] = useState<boolean>(false)
  const [history, setHistory] = useState<{ [key: string]: number }>({})
  const [historyData, setHistoryData] = useState<{ time: string; data: number }[]>([])

  const onReset = () => {
    saveHistoryLocalStorage()
    form.resetFields()
    setIsResetForm(!isResetForm)
    setTotalMoney(0)
    localStorage.removeItem("storedValues")
  }

  const saveHistoryLocalStorage = () => {
    const date = new Date()
    const currentTime = dayjs(date).format("HH:mm DD/MM/YYYY")
    const newHistoryItem = {
      time: currentTime,
      data: totalMoney,
    }
    const newHistory = [...historyData, newHistoryItem]
    setHistoryData(newHistory)
    localStorage.setItem("history", JSON.stringify(newHistory))
  }

  const removeHistoryLocalStorage = () => {
    setHistoryData([])
    localStorage.removeItem("history")
  }

  useEffect(() => {
    const storedValue = localStorage.getItem("storedValues")
    const historyData = localStorage.getItem("history")
    if (storedValue) {
      const values = JSON.parse(storedValue)
      setHistory(values)
      for (const property in values) {
        const key = property
        form.setFieldValue(key, values[property] ?? 0)
      }
    }
    if (historyData) {
      const data = JSON.parse(historyData)
      setHistoryData(data)
    }
  }, [])

  const handleValuesChange = (values: any, allValues: any) => {
    let totalMoney = 0
    for (const key in allValues) {
      if (allValues[key]) {
        totalMoney += CurrencyTypes[key as keyof typeof CurrencyTypes] * allValues[key]
      }
    }
    const storedValues = form.getFieldsValue()
    localStorage.setItem("storedValues", JSON.stringify(storedValues))
    setTotalMoney(totalMoney)
  }

  return (
    <>
      <div>
        <Card>
          <Flex justify="space-between">
            <Typography.Title level={3} style={styles.title}>
              Bảng đếm các loại tiền
            </Typography.Title>
            <Space>
              <Button icon={<ReloadOutlined />} onClick={() => removeHistoryLocalStorage()}>
                Xoá lịch sử
              </Button>
            </Space>
          </Flex>
          <Divider />
          <Row justify="space-around">
            <Card style={styles.card}>
              <InputNumber
                defaultValue={"Số tờ"}
                disabled
                style={styles.input}
                addonBefore={<span style={styles.spanBefore}>Mệnh giá</span>}
                addonAfter={<span style={styles.spanAfter}> Tổng</span>}
              />
              <Form form={form} name="control-hooks" style={{ maxWidth: 600 }} onValuesChange={handleValuesChange}>
                <MoneyFormItem name="currency500" isReset={isResetForm} history={history} />
                <MoneyFormItem name="currency200" isReset={isResetForm} history={history} />
                <MoneyFormItem name="currency100" isReset={isResetForm} history={history} />
                <MoneyFormItem name="currency50" isReset={isResetForm} history={history} />
                <MoneyFormItem name="currency20" isReset={isResetForm} history={history} />
                <MoneyFormItem name="currency10" isReset={isResetForm} history={history} />
                <MoneyFormItem name="currency5" isReset={isResetForm} history={history} />
                <MoneyFormItem name="currency2" isReset={isResetForm} history={history} />
                <MoneyFormItem name="currency1" isReset={isResetForm} history={history} />
                <Row justify="space-around">
                  <Space style={styles.space}>
                    Tổng tiền:
                    {totalMoney ? <>{formatMoney(totalMoney)}</> : 0} VNĐ
                  </Space>
                  <Space>
                    <Button icon={<ReloadOutlined />} onClick={onReset}>
                      Làm mới
                    </Button>
                  </Space>
                </Row>
              </Form>
            </Card>
            <Card title="Lịch sử" style={styles.card}>
              {historyData.length > 0 && (
                <List
                  dataSource={historyData}
                  size="small"
                  renderItem={(item: any, index) => (
                    <List.Item>
                      <List.Item.Meta title={item.time} description={`${formatMoney(item.data)} VNĐ`} />
                    </List.Item>
                  )}
                />
              )}
            </Card>
          </Row>
        </Card>
      </div>
    </>
  )
}

export default View
const styles = {
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  card: {
    justifyContent: "center",
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
    width: 550,
  },

  input: {
    marginBottom: "0.5rem",
    width: 500,
  },
  spanBefore: {
    width: 100,
    display: "inline-block",
  },
  spanAfter: {
    width: 200,
    display: "inline-block",
  },
  space: {
    width: 300,
  },
}
