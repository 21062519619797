import {
  createCheckIn,
  fetchCheckInById,
  fetchStaffCheckIn,
  updateCheckIn,
} from "@/modules/hrs/check_ins/services/checkIn.services"
import { fetchStaff, fetchStaffs } from "@/modules/hrs/staffs/services/staff.services"
import { Checkin, MAP_CHECKIN, Staff } from "@/modules/hrs/staffs/types"
import { checkIsDateInMonth, checkIsSameDate, getMonthStartEndDate, normalizeText, notificationAction } from "@/utils"
import { NotificationEnum } from "@/utils/constants"
import { Button, Calendar, Col, Modal, Radio, RadioChangeEvent, Row, Select, Space, Tag } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { debounce } from "lodash"
import { useEffect, useState } from "react"
import "rsuite/dist/rsuite.css"
import "../css/calendar.css"

interface CheckInProps {
  openProps: boolean
  cancelProps: () => void
  isNew?: boolean
  id?: string
  idStaff?: string
  month?: string
}
const CheckInForm: React.FC<CheckInProps> = props => {
  const [loading, setLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [checkInValues, setCheckInValues] = useState<{ date: Date | string; value: number }[]>([])
  const [existedCheckIn, setExistedCheckIn] = useState<Date | string | null>(null)
  const [monthStartDate, setMonthStartDate] = useState<Date>(new Date())
  const [monthEndDate, setMonthEndDate] = useState<Date>(new Date())
  const [paySheetStaffName, setPaySheetStaffName] = useState<string>("")
  const [paySheetDate, setPaySheetDate] = useState<string>("")
  const [paySheetMonth, setPaySheetMonth] = useState<Date | null>(null)
  const [dataStaffs, setDataStaffs] = useState<{ value: string; label: string }[]>([])
  const [newStaffId, setNewStaffId] = useState<number | null>(null)
  const [isSettlement, setIsSettlement] = useState<boolean>(false)

  const resetMonth = (date?: Date | string) => {
    const month = getMonthStartEndDate(date ? new Date(date) : new Date())
    setMonthStartDate(month.startDate)
    setMonthEndDate(month.endDate)
  }

  useEffect(() => {
    setPaySheetDate(`Tháng ${monthStartDate.getMonth() + 1}/${monthStartDate.getFullYear()}`)
    if (props.idStaff) {
      fetchStaff(props.idStaff).then(res => {
        setPaySheetStaffName(res.data.name)
      })
      fetchStaffCheckIn(props.idStaff, dayjs(monthStartDate).format("YYYY-MM-DD")).then(res => {
        if (res.data) {
          setCheckInValues(res.data.value)
        } else {
          setCheckInValues([])
        }
      })
    }
  }, [props.idStaff, monthStartDate])

  useEffect(() => {
    if (props.id) {
      fetchCheckInById(props.id).then(res => {
        const month = new Date(res.data.month)
        resetMonth(month)
        setPaySheetMonth(month)
        setPaySheetStaffName(res.data.staff.name)
        setPaySheetDate(`Tháng ${month.getMonth() + 1}/${month.getFullYear()}`)
        setCheckInValues(res.data.value)
        setIsSettlement(res.data.settlement)
      })
    }
  }, [props.id])

  function handleDateSelect(date: Dayjs) {
    if (isSettlement) return
    setSelectedDate(date.toDate())
    const existedCheckIn = checkInValues.find(checkIn => checkIsSameDate(date.toDate(), checkIn.date))
    if (existedCheckIn) {
      setExistedCheckIn(existedCheckIn.date)
    } else {
      setExistedCheckIn(null)
    }
  }

  const handleChange = ({ target: { value } }: RadioChangeEvent) => {
    if (isSettlement) return
    if (existedCheckIn) {
      const newCheckIns = checkInValues.filter(checkin => !checkIsSameDate(checkin.date, existedCheckIn))
      setCheckInValues([...newCheckIns, { date: dayjs(existedCheckIn).format("YYYY-MM-DD"), value }])
    } else if (selectedDate) {
      setCheckInValues([...checkInValues, { date: dayjs(selectedDate).format("YYYY-MM-DD"), value }])
    }
  }

  const handleSave = async () => {
    const staffId = props.idStaff ? props.idStaff : newStaffId ? newStaffId : null
    if (staffId) {
      setLoading(true)
      const createdDate = new Date(monthStartDate)
      createdDate.setDate(15)
      const response = await createCheckIn({
        staff_id: `${staffId}`,
        value: checkInValues,
        month: createdDate,
      })
      if (response.statusCode === 200) {
        setLoading(false)
        props.cancelProps()
        notificationAction(NotificationEnum.CREATED, NotificationEnum.SUCCESS)
      } else {
        setLoading(false)
        notificationAction(response.message, NotificationEnum.ERROR)
      }
    }
    if (props.id) {
      setLoading(true)
      const response = await updateCheckIn(props.id, {
        value: checkInValues,
      })
      if (response.statusCode === 200) {
        setLoading(false)
        props.cancelProps()
        notificationAction(NotificationEnum.UPDATED, NotificationEnum.SUCCESS)
      } else {
        setLoading(false)
        notificationAction(response.message, NotificationEnum.ERROR)
      }
    }
  }

  useEffect(() => {
    resetMonth()
  }, [])

  const searchStaffs = async (searchValue: string) => {
    if (searchValue) {
      fetchStaffs({ search: normalizeText(searchValue) }).then((res: any) => {
        if (res.data.items && res.data.items.length > 0) {
          const mappedStaffs = res.data.items.map((staff: Staff) => ({ value: staff.id, label: staff.name }))
          setDataStaffs(mappedStaffs)
        } else {
          setDataStaffs([])
        }
      })
    } else {
      setDataStaffs([])
    }
  }

  const onSearchStaffs = debounce(nextValue => searchStaffs(nextValue), 200)

  function renderCell(date: Dayjs) {
    const isInMonth = checkIsDateInMonth(
      date.toDate(),
      monthStartDate,
      new Date(monthEndDate.getFullYear(), monthEndDate.getMonth() + 1, 0),
    )
    const checkInData = checkInValues.find(item => checkIsSameDate(date.toDate(), item.date))
    const Mapped_Checkin = (value: Checkin) => {
      return <>{MAP_CHECKIN[value]}</>
    }
    return (
      <div
        className={`custom-calendar-cell ${(date.day() + 1) % 2 === 0 || date.day() === 0 ? "bg-gray" : ""}`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: "0.5rem",
          position: "relative",
        }}
      >
        {!isInMonth && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.25)",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          ></div>
        )}
        <p>{date.date()}</p>
        <Radio.Group
          buttonStyle="solid"
          name="radiogroup"
          onChange={handleChange}
          value={checkInData ? checkInData?.value : null}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <Radio.Button className="customRadioButton" value={Checkin.FULLDAY}>
            1
          </Radio.Button>
          <Radio.Button className="customRadioButton" value={Checkin.HALFDAY}>
            1/2
          </Radio.Button>
          <Radio.Button className="customRadioButton" value={Checkin.OFF}>
            0
          </Radio.Button>
        </Radio.Group>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {checkInData && (
            <Tag
              className="statusCheckin"
              color={checkInData.value === 1 ? "green" : checkInData.value === 0.5 ? "blue" : "red"}
            >
              <b>{Mapped_Checkin(checkInData.value)}</b>
            </Tag>
          )}
        </div>
      </div>
    )
  }

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            key="cancel"
            onClick={() => {
              props.cancelProps()
            }}
          >
            Hủy
          </Button>
          {!isSettlement && (
            <Button type="primary" onClick={handleSave} loading={loading}>
              Lưu
            </Button>
          )}
        </Space>
      </>
    )
  }
  return (
    <Modal
      open={props.openProps}
      width={1200}
      onCancel={props.cancelProps}
      afterClose={() => {
        resetMonth()
        setNewStaffId(null)
      }}
      footer={renderFooter()}
      maskClosable={false}
      destroyOnClose
      forceRender
    >
      <Calendar
        fullCellRender={renderCell}
        onSelect={handleDateSelect}
        validRange={[dayjs(monthStartDate), dayjs(monthEndDate).add(1, "day")]}
        value={paySheetMonth ? dayjs(paySheetMonth) : undefined}
        headerRender={({ value, onChange }) => {
          const year = paySheetMonth ? dayjs(paySheetMonth).year() : value.year()
          const month = paySheetMonth ? dayjs(paySheetMonth).month() : value.month()
          const start = 0
          const end = 12
          const monthOptions = []

          const months = [
            "Tháng 1",
            "Tháng 2",
            "Tháng 3",
            "Tháng 4",
            "Tháng 5",
            "Tháng 6",
            "Tháng 7",
            "Tháng 8",
            "Tháng 9",
            "Tháng 10",
            "Tháng 11",
            "Tháng 12",
          ]

          for (let i = start; i < end; i++) {
            monthOptions.push(
              <Select.Option key={i} value={i} className="month-item">
                {months[i]}
              </Select.Option>,
            )
          }

          const options = []
          for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
              <Select.Option key={i} value={i} className="year-item">
                {i}
              </Select.Option>,
            )
          }
          return (
            <div style={{ padding: 8 }}>
              <Row gutter={8}>
                {!paySheetMonth && (
                  <Col>
                    <Select
                      size="middle"
                      className="my-year-select"
                      value={year}
                      onChange={newYear => {
                        const now = value.clone().year(newYear)
                        const month = getMonthStartEndDate(now.toDate())
                        setMonthStartDate(month.startDate)
                        setMonthEndDate(month.endDate)
                        onChange(now)
                      }}
                    >
                      {options}
                    </Select>
                  </Col>
                )}
                {!paySheetMonth && (
                  <Col>
                    <Select
                      size="middle"
                      value={month}
                      onChange={newMonth => {
                        const now = value.clone().month(newMonth)
                        const month = getMonthStartEndDate(now.toDate())
                        setMonthStartDate(month.startDate)
                        setMonthEndDate(month.endDate)
                        onChange(now)
                      }}
                    >
                      {monthOptions}
                    </Select>
                  </Col>
                )}
                {props.isNew && (
                  <Select
                    showSearch
                    allowClear
                    placeholder="Nhập tìm nhân viên"
                    filterOption={false}
                    options={dataStaffs}
                    onSearch={onSearchStaffs}
                    onChange={e => setNewStaffId(e)}
                    onClear={() => setDataStaffs([])}
                    style={{ minWidth: "16rem" }}
                  />
                )}
                <Col style={{ display: "flex", alignItems: "center" }}>
                  {paySheetStaffName && paySheetDate && (
                    <p style={{ fontSize: "1rem" }}>
                      Bảng chấm công <span style={{ fontWeight: "600" }}>{paySheetDate}</span> của nhân viên{" "}
                      <span style={{ fontWeight: "600" }}>{paySheetStaffName}</span>
                    </p>
                  )}
                </Col>
              </Row>
            </div>
          )
        }}
      />
    </Modal>
  )
}

export default CheckInForm
