import { CurrencyRouter } from "@/modules/finances/currency"
import { PayableDebtsRouter } from "@/modules/finances/payable_debts"
import { PropertiesRouter } from "@/modules/finances/properties"
import { ReceiptsRouter } from "@/modules/finances/receipts"
import { ReceivableDebtsRouter } from "@/modules/finances/receivable_debts"
import { Route, Routes } from "react-router-dom"

export const FinancesRouter = () => {
  return (
    <Routes>
      <Route path="receipts/*" element={<ReceiptsRouter />} />
      <Route path="payable-debts/*" element={<PayableDebtsRouter />} />
      <Route path="receivable-debts/*" element={<ReceivableDebtsRouter />} />
      <Route path="currency/*" element={<CurrencyRouter />} />
      <Route path="properties/*" element={<PropertiesRouter />} />
    </Routes>
  )
}
