import { ActionTypes, ErrorCodes } from "../../constants"
import { ApiNotFound, BadRequest, NetworkError, RequestError, Unauthorized } from "../../exceptions"
import store from "../../redux/store"

const { BAD_REQUEST, UNAUTHORIZED, API_NOT_FOUND } = ErrorCodes

interface ResProps {
  message?: string
  data?: any
  status?: number
}

export const onHttpRequestError = (res: ResProps, apiName = "") => {
  // handle network error
  if (res?.message === "Network Error") {
    throw new NetworkError(res.message, apiName, null)
  }

  // handle error
  if (res && res.status) {
    const { data, status, message = "" } = res
    switch (status) {
      case BAD_REQUEST: {
        throw new BadRequest(message, apiName, data)
      }
      case UNAUTHORIZED: {
        store.dispatch({ type: ActionTypes.SESSION_EXPIRED_SUCCESS, payload: null })
        store.dispatch({
          type: ActionTypes.NOTIFICATION,
          payload: {
            title: "Phiên làm việc hết hạn",
            message: "Vui lòng đăng nhập lại để làm việc",
            type: "error",
          },
        })
        throw new Unauthorized(message, apiName, data)
      }
      case API_NOT_FOUND: {
        throw new ApiNotFound("Không tìm thấy API", apiName, data)
      }
      default: {
        throw new RequestError(message, apiName, data)
      }
    }
  }

  return res
}
