import { ConfigProvider } from "antd"
import viVN from "antd/lib/locale/vi_VN"
import dayjs from "dayjs"
import "dayjs/locale/vi"
import { Provider as ReduxProvider } from "react-redux"

import { AuthProvider } from "./components/auth"
import { Notifications } from "./components/Notifications"
import { Navigator } from "./Navigator"
import store from "./redux/store"
dayjs.locale("vi")

const App = () => {
  return (
    <ConfigProvider locale={viVN}>
      <ReduxProvider store={store}>
        <AuthProvider>
          <Navigator />
          <Notifications />
        </AuthProvider>
      </ReduxProvider>
    </ConfigProvider>
  )
}

export default App
