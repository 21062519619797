export enum FinanceReportEnum {
  INCOME = "DOANH THU",
  EXPENSE = "CHI PHÍ",
  GROSS_PROFIT = "LỢI NHUẬN",
}

export interface DataFinanceReport {
  table: TableFinances[]
  chart: ChartFinances
  profitTable: ProfitTableType[]
}

export interface TableFinances {
  name: string
  type: string
  value: number
  percent_of_total?: number
}

export interface ChartFinances {
  incomeExpense: ItemChart
  operatingExpense: ItemChart
  otherExpense: ItemChart
}
export interface ItemChart {
  labels: string[]
  series: number[]
}

export interface ProfitTableType {
  name: string
  value: number
}

export enum SELECT_TIME {
  LAST30DAYS = "last30days",
  LASTMONTH = "lastMonth",
  THISMONTH = "thisMonth",
  THISQUARTER = "thisQuarter",
}
