import { AddPermission, UpdatePermission } from "@/modules/manages/users"
import { Http } from "@/network/http"
import { Filters } from "@/utils/constants"

export const API_PREFIX_PATH = "/permissions"

export const fetchPermissions = async (filters?: Filters) => {
  return await Http.get(`${API_PREFIX_PATH}`, { params: filters })
}

export const fetchPermission = async (id: string) => {
  return await Http.get(`${API_PREFIX_PATH}/${id}`)
}

export const deletePermission = async (id: string) => {
  return await Http.post(`${API_PREFIX_PATH}/delete/${id}`)
}

export const createPermission = async (data?: AddPermission) => {
  return await Http.post(`${API_PREFIX_PATH}`, data)
    .then(response => {
      return response
    })
    .catch(err => {
      throw new Error(err)
    })
}

export const updatePermission = async (id: string, data?: UpdatePermission) => {
  return await Http.put(`${API_PREFIX_PATH}/${id}`, data)
}

export const fetchPermissionsByRouteName = async () => {
  return await Http.get(`${API_PREFIX_PATH}/by-route-name`)
}
