import { AuthDto } from "@/__generated__/api-v1"
import { useLocalStorageState } from "ahooks"
import { createContext, useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { ACCESS_TOKEN, ActionTypes, IS_AUTHENTICATED, USER_INFO } from "../../constants"

import { postRequest } from "@/network/http"
import { handleLogout } from "@/redux/actions/auth"
import store, { RootState } from "@/redux/store"
import { useSelector } from "react-redux"

export interface AuthContext {
  login: (data: AuthDto) => void
  logout: () => void
  userInfo?: string | null
  isAuthenticated: boolean | null
  token?: string
}

const authContext = createContext<AuthContext>({} as AuthContext)

const useAuth = (): AuthContext => {
  const { session } = useSelector((state: RootState) => state.auth)
  const [isAuthenticated, setIsAuthenticated] = useLocalStorageState<string | null>(IS_AUTHENTICATED)
  const [accessToken, setAccessToken] = useLocalStorageState<string | null>(ACCESS_TOKEN)

  const [userInfo, setUserInfo] = useLocalStorageState<string | null>(USER_INFO)

  const [token, setToken] = useState<string>("")

  const dispatch = useDispatch()

  const login = async (data: AuthDto) => {
    // dispatch(handleLogin(data))
    try {
      const newData = {
        username: data.username,
        password: data.password,
      }
      const response = await postRequest("auth/sign-in", newData)
      if (!response) {
        throw new Error("Failed to add data to the database")
      }
      if (response.data.access_token) {
        setAccessToken(response.data.access_token)
      }
      if (response.data.user_fullname) {
        setUserInfo(JSON.stringify({ user_fullname: response.data.user_fullname }))
      }
      setIsAuthenticated(IS_AUTHENTICATED)
      store.dispatch({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: "Đăng nhập",
          message: "Đăng nhập thành công!",
          type: "success",
        },
      })
    } catch (error) {
      alert("Tài khoản hoặc mật khẩu sai!")
    }
  }

  useEffect(() => {
    if (session.isExpire) {
      setIsAuthenticated(null)
      setAccessToken(null)
      setUserInfo(null)
    }
  }, [session])

  const logout = () => {
    dispatch(handleLogout())
    setAccessToken(null)
    setUserInfo(null)
    setIsAuthenticated(null)
  }

  return {
    userInfo,
    login,
    logout,
    isAuthenticated: !!isAuthenticated,
    token,
  }
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuthContext = () => useContext(authContext)
