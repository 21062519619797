export enum CurrencyTypes {
  currency500= 500000,
  currency200= 200000,
  currency100= 100000,
  currency50= 50000,
  currency20= 20000,
  currency10= 10000,
  currency5= 5000,
  currency2= 2000,
  currency1= 1000,
}
