import { GET_USER_PROFILE_SUCCESS } from "../../constants"
import { IProfileState } from "../types"

interface ActionProps {
  type: string
  payload: any
}

const initState: IProfileState = {
  user: undefined,
}

export const profile = (state: IProfileState = initState, action: ActionProps) => {
  const { type, payload } = action

  switch (type) {
    case GET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        user: {
          user_fullname: payload.user_fullname,
        },
      }
    }

    default: {
      return state
    }
  }
}
