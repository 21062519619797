import { ActionTypes, Paths } from "@/constants"
import store from "@/redux/store"
import { Navigate, Outlet } from "react-router-dom"
import { useAuthContext } from "../components/auth"

export const RequireAuth: React.FC = () => {
  const auth = useAuthContext()

  // @TODO temporarily bypass auth
  if (!auth.isAuthenticated) {
    return <Navigate to={Paths.PUBLIC.LOGIN.PATH} />
  }

  if (auth.userInfo) {
    const userInfo = JSON.parse(auth.userInfo)
    store.dispatch({
      type: ActionTypes.GET_USER_PROFILE_SUCCESS,
      payload: {
        ...userInfo,
      },
    })
  }

  return <Outlet />
}
