import {
  createInternalDebt,
  fetchInternalDebt,
  updateInternalDebt,
} from "@/modules/hrs/internal_debts/services/internaldebts.services"
import { InternalDebt } from "@/modules/hrs/internal_debts/types"
import { Staff } from "@/modules/hrs/staffs"
import { fetchStaffs } from "@/modules/hrs/staffs/services/staff.services"
import {
  dateFormatListMonth,
  formatterInputNumber,
  normalizeText,
  notificationAction,
  notificationCreateSuccess,
  notificationUpdateSuccess,
} from "@/utils"
import { NotificationEnum, StatusEnum } from "@/utils/constants"

import { Button, DatePicker, Form, InputNumber, Modal, Select, Space } from "antd"
import TextArea from "antd/es/input/TextArea"
import dayjs from "dayjs"
import { useEffect, useState } from "react"

interface BaseUpsertForm {
  openProps?: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: string
  idEdit?: string
}

const UpsertForm: React.FC<BaseUpsertForm> = props => {
  const [form] = Form.useForm()
  const [dataStaffs, setDataStaffs] = useState<Staff[]>([])
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const onReset = () => {
    form.resetFields()
  }

  const handleCompleteForm = () => {
    onReset()
    props.refreshProps()
    props.cancelProps()
    props.idEdit ? notificationUpdateSuccess() : notificationCreateSuccess()
    setButtonLoading(false)
  }

  const handleAddInternalDebts = async () => {
    try {
      const values = await form.validateFields()
      const newData: InternalDebt = {
        ...values,
        status: StatusEnum.ACTIVE,
      }
      setButtonLoading(true)
      const response = await createInternalDebt(newData)
      if (response.statusCode === 200) {
        handleCompleteForm()
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Addition failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  useEffect(() => {
    if (props.actionType === "CREATE") {
      form.resetFields()
    }

    const onInitEdit = async (idEdit: string) => {
      const debt = await fetchInternalDebt(idEdit)

      form.setFieldsValue({
        borrower_id: debt.data.borrower_id,
        amount: debt.data.amount,
        settlement: debt.data.settlement,
        date_happened: debt.data.date_happened ? dayjs(debt.data.date_happened) : dayjs(new Date()),
        note: debt.data.note ?? "",
      })
    }
    if (props.idEdit) {
      onInitEdit(props.idEdit)
    }
  }, [props.actionType, props.idEdit])

  const handleUpdateInternalDebts = async () => {
    try {
      if (props.idEdit) {
        const values = await form.validateFields()
        const editData = {
          ...values,
          status: StatusEnum.ACTIVE,
        }
        setButtonLoading(true)
        const response = await updateInternalDebt(props.idEdit, editData)
        if (response.statusCode === 200) {
          handleCompleteForm()
        }
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Edit failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button
            key="cancel"
            onClick={() => {
              props.cancelProps()
              onReset()
            }}
          >
            Hủy
          </Button>
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={handleUpdateInternalDebts} loading={buttonLoading}>
              Lưu
            </Button>
          )}
          {props.actionType === "CREATE" && (
            <Button type="primary" onClick={() => handleAddInternalDebts()} loading={buttonLoading}>
              Thêm mới
            </Button>
          )}
        </Space>
      </>
    )
  }

  const getCustomers = async () => {
    try {
      const response = await fetchStaffs()
      if (response?.data) {
        setDataStaffs(response.data.items)
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }
  useEffect(() => {
    getCustomers()
  }, [])

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    normalizeText((option?.label ?? "").toLowerCase()).includes(normalizeText(input.toLowerCase()))
  return (
    <Modal
      title={
        props.actionType === "CREATE" ? "Thêm phiếu ứng lương của nhân viên" : "Chỉnh sửa phiếu ứng lương của nhân viên"
      }
      open={props.openProps}
      width={600}
      onCancel={props.cancelProps}
      footer={renderFooter()}
    >
      <Form
        name="wrap"
        labelCol={{ flex: "130px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        style={{ maxWidth: 600 }}
        form={form}
      >
        <Form.Item
          label="Tên nhân viên"
          name="borrower_id"
          rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
        >
          <Select
            showSearch
            placeholder="Nhập tên nhân viên"
            optionFilterProp="children"
            filterOption={filterOption}
            options={dataStaffs.map(item => ({
              value: item.id as string,
              label: item.name,
            }))}
          />
        </Form.Item>

        <Form.Item label="Tiền" name="amount" rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}>
          <InputNumber
            style={styles.inputNumber}
            placeholder="Nhập tiền hóa đơn"
            formatter={value => formatterInputNumber(value)}
            addonAfter="VNĐ"
            step={10000}
            min={0}
          />
        </Form.Item>

        <Form.Item label="Trạng thái" name="settlement" initialValue={false}>
          <Select
            placeholder="Vui lòng chọn trạng thái"
            style={{ width: "100%" }}
            options={[
              { value: true, label: "Đã khấu trừ" },
              { value: false, label: "Chưa khấu trừ" },
            ]}
            defaultValue={false}
          />
        </Form.Item>

        <Form.Item label="Tháng ứng lương" name="date_happened" rules={[{ required: true, message: "Vui lòng chọn!" }]}>
          <DatePicker placeholder="Chọn tháng" format={dateFormatListMonth} picker="month" />
        </Form.Item>

        <Form.Item label="Ghi chú" name="note">
          <TextArea placeholder="Ghi chú cho khoản ứng" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default UpsertForm
const styles = {
  inputNumber: {
    width: "350px",
  },
}
