import { AssetName, PropertyType } from "@/modules/finances/properties/types"
import { Http } from "@/network/http"
import { notificationAction } from "@/utils"
import { NotificationEnum, StatusEnum } from "@/utils/constants"

interface Filters {
  search?: string
  status?: string
  limit?: number
  offset?: number
  page?: number
}

export const API_PREFIX_PATH = "/properties"

export const fetchProperties = async (filters?: Filters) => {
  return await Http.get(`${API_PREFIX_PATH}`, { params: filters })
}

export const fetchProperty = async (id: string) => {
  return await Http.get(`${API_PREFIX_PATH}/${id}`)
}

export const createProperty = async (data?: PropertyType) => {
  return await Http.post(`${API_PREFIX_PATH}`, data)
}

export const fetchAssets = async () => {
  return await Http.get("/assets")
}
export const createAsset = async (data?: AssetName) => {
  return await Http.post("/assets", data)
}

export const updateProperty = async (id: string, data?: PropertyType) => {
  return await Http.put(`${API_PREFIX_PATH}/${id}`, data)
}

export const deleteProperty = async (data: PropertyType) => {
  try {
    const updatedData = { ...data }
    if (!updatedData.id) return
    const response = await updateProperty(updatedData.id, { ...updatedData, status: StatusEnum.DELETED })
    if (response.statusCode === 200) {
      notificationAction(NotificationEnum.DELETED, NotificationEnum.SUCCESS)
    }
  } catch (error) {
    console.error("Addition failed:", error)
    notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
  }
}
