export interface ReceiptCategory {
  id?: string
  category: string
  type: string
  created_at?: string
  updated_at?: string
  status: string
  extra_type: string
}

export enum TypeReceiptEnum {
  EXPENSE = "expense",
  INCOME = "income",
}

export const Mapped_TypeReceipt = {
  [TypeReceiptEnum.EXPENSE]: "Danh mục chi",
  [TypeReceiptEnum.INCOME]: "Danh mục thu",
}

export enum ClassifyReceiptCategoriesType {
  OPERATING = "operating",
  OTHER = "other",
}

export const MAP_CLASSIFY_RECEIPT_CATEGORIES = {
  [ClassifyReceiptCategoriesType.OPERATING]: "Hoạt động",
  [ClassifyReceiptCategoriesType.OTHER]: "Quản lý",
}
