import { Card } from "antd"
import { ApexOptions } from "apexcharts"
import ReactApexChart from "react-apexcharts"

const ApexChartPie = ({
  dataSeries,
  dataLabels,
  title,
}: {
  dataSeries: number[]
  dataLabels: string[]
  title: string
}) => {
  const pieChartOption: ApexOptions = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: dataLabels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],

    title: {
      text: title,
      align: "center",
      style: { fontFamily: "Segoe UI", fontSize: "20px", fontWeight: "bold", color: "#000000" },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return new Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(value)
        },
      },
    },
  }

  return (
    <Card>
      <ReactApexChart options={pieChartOption} series={dataSeries} type="pie" height={350} width={"100%"} />
    </Card>
  )
}

export default ApexChartPie
