import { fetchStaffCheckIn } from "@/modules/hrs/check_ins/services/checkIn.services"
import { createPaySheet, fetchPaySheet, updatePaySheet } from "@/modules/hrs/paysheets/services/paysheet.services"
import { Paysheet } from "@/modules/hrs/paysheets/types"
import { Staff } from "@/modules/hrs/staffs"
import { fetchStaffs } from "@/modules/hrs/staffs/services/staff.services"
import { fetchSettings } from "@/modules/settings/workdays/services/settings.serivces"
import {
  dateFormatList,
  dateFormatListMonth,
  filterOption,
  formatterInputNumber,
  getExpectedWorkDays,
  notificationAction,
  notificationCreateSuccess,
  notificationUpdateSuccess,
} from "@/utils"
import { NotificationEnum, StatusEnum } from "@/utils/constants"
import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
interface UpsertFormProps {
  openProps: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: string
  idEdit?: string
}
const UpsertForm: React.FC<UpsertFormProps> = props => {
  const [form] = Form.useForm()
  const [dataStaffs, setDataStaffs] = useState<Staff[]>([])
  const [workDays, setWorkDays] = useState<number[]>([])
  const [expectedWorkDays, setExpectedWorkDays] = useState<number>(30)
  const [nameStaff, setNameStaff] = useState<string>("")
  const [staffId, setStaffId] = useState<string | undefined>("")
  const [date, setDate] = useState<Date | string>("")
  const [hasLinkToCheckIn, setHasLinkToCheckIn] = useState<boolean>(false)
  const [linkToCheckIn, setLinkToCheckIn] = useState<string>("")
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const onReset = () => {
    form.resetFields()
    setNameStaff("")
    setStaffId("")
    setHasLinkToCheckIn(false)
    setLinkToCheckIn("")
    setDate("")
  }

  const handleCompleteForm = () => {
    onReset()
    props.refreshProps()
    props.cancelProps()
    props.idEdit ? notificationUpdateSuccess() : notificationCreateSuccess()
    setButtonLoading(false)
  }

  useEffect(() => {
    if (props.actionType === "CREATE") {
      form.resetFields()
      setNameStaff("")
    }
  }, [props.actionType])

  const handleAddPaySheet = async () => {
    try {
      const values = await form.validateFields()
      const totalWorkDays = values.total_work_days
      if (totalWorkDays === 0) {
        form.setFields([
          {
            name: "total_work_days",
            errors: [
              `${
                hasLinkToCheckIn
                  ? "Số ngày làm việc thực tế không được lớn hơn số ngày làm việc tối đa trong tháng"
                  : "Vui lòng kiểm tra lại bảng chấm công của nhân viên đã được duyệt bảng chấm công chưa"
              }`,
            ],
          },
        ])
        return
      } else {
        const newData: Paysheet = {
          ...values,
          status: StatusEnum.ACTIVE,
        }
        setButtonLoading(true)
        const response = await createPaySheet(newData)
        if (response.statusCode === 200) {
          handleCompleteForm()
        } else {
          setButtonLoading(false)
          notificationAction(`${response.message}`, NotificationEnum.ERROR)
        }
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Addition failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  const handleUpdatePaySheet = async () => {
    try {
      const values = await form.validateFields()
      const editData = {
        ...values,
        status: StatusEnum.ACTIVE,
      }
      if (props.idEdit) {
        setButtonLoading(true)
        const response = await updatePaySheet(props.idEdit, editData)
        if (response.statusCode === 200) {
          handleCompleteForm()
        }
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Edit failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  const getStaffs = async () => {
    try {
      const response = await fetchStaffs({ limit: 1000 })
      if (response?.data) {
        setDataStaffs(response.data.items)
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  const getWorkdays = async () => {
    try {
      const response = await fetchSettings("workdays")
      let workDays = [0, 1, 2, 3, 4, 5, 6]
      const today = new Date()
      if (response.data.value && response.data.value.length) {
        workDays = response.data.value
      }
      const expectedWorkDays = getExpectedWorkDays(today.getMonth() + 1, today.getFullYear(), workDays)
      setWorkDays(workDays)
      setExpectedWorkDays(expectedWorkDays)
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  const getCheckIns = async (staffId: string, month: Date | string) => {
    let hasLinkToCheckIn = false
    let totalWorkDays = 0
    let linkToCheckIn = ""
    const correctMonth = month ? month : dayjs(new Date()).format("YYYY-MM-DD")
    const res = await fetchStaffCheckIn(staffId, correctMonth)

    if (res.data) {
      const isDoneCheckIn = res.data.settlement
      const status = res.data.status
      if (isDoneCheckIn && status !== StatusEnum.DELETED) {
        hasLinkToCheckIn = true
        const mappedValue = res.data.value.map((checkIn: any) => checkIn.value)
        const totalValue = mappedValue.reduce((prev: any, curr: any) => prev + curr, 0)
        totalWorkDays = totalValue
        linkToCheckIn = `/hrs/checkin/${res.data.id}`
      }
    }
    form.setFields([
      {
        name: "total_work_days",
        value: totalWorkDays,
        errors: [],
      },
    ])

    setHasLinkToCheckIn(hasLinkToCheckIn)
    setLinkToCheckIn(linkToCheckIn)
  }

  useEffect(() => {
    if (staffId) {
      const today = new Date()
      getCheckIns(staffId, date ? date : today.toLocaleDateString())
    }
  }, [staffId, date])

  useEffect(() => {
    getStaffs()
    getWorkdays()
  }, [])

  useEffect(() => {
    const onInitEdit = async (idEdit: string) => {
      const paySheet = await fetchPaySheet(idEdit)
      form.setFieldsValue({
        name: paySheet.data.name,
        staff_id: paySheet.data.staff_id,
        total_work_days: paySheet.data.total_work_days,
        basic_income: paySheet.data.basic_income,
        extra_income: paySheet.data.extra_income ?? 0,
        advance_payment: paySheet.data.advance_payment ?? 0,
        total_income: paySheet.data.total_income,
        negative_income: paySheet.data.negative_income ?? 0,
        final_income: paySheet.data.final_income,
        has_settle: paySheet.data.has_settle,
        payday: dayjs(paySheet.data.payday),
        paysheet_date: dayjs(paySheet.data.paysheet_date),
      })
      if (workDays.length === 0) return
      const date = dayjs(paySheet.data.paysheet_date)
      const days = getExpectedWorkDays(date.month() + 1, date.year(), workDays)
      form.setFieldValue("expected_work_days", days)
      if (paySheet.data.total_work_days) {
        form.setFieldValue(
          "income_by_day",
          Math.round((paySheet.data.basic_income / days) * paySheet.data.total_work_days),
        )
      }
    }

    if (props.idEdit) {
      onInitEdit(props.idEdit)
    }
  }, [props.idEdit, workDays])

  const handleValuesChange = async (changedValues: any, allValues: any) => {
    const key = Object.keys(changedValues)[0]
    const value = Object.values(changedValues)[0]

    if (key === "staff_id") {
      const staff = dataStaffs.find(s => s.id === value)
      if (!staff) {
        form.setFieldValue("basic_income", 0)
        return
      }

      let advancePayment = 0
      if (staff.internal_debts && staff.internal_debts?.length > 0) {
        staff.internal_debts.forEach(debt => {
          if (debt.status === StatusEnum.ACTIVE && !debt.settlement) {
            advancePayment += debt.amount
          }
        })
      }
      form.setFieldValue("advance_payment", advancePayment)
      form.setFieldValue("basic_income", staff.basic_income)
      const defaultDate = new Date(form.getFieldValue("paysheet_date"))
      form.setFieldValue("name", `${staff.name} - ${defaultDate.getMonth() + 1}/${defaultDate.getFullYear()}`)
      setNameStaff(staff.name)
      setStaffId(staff.id)
      if (staff.id) {
        await getCheckIns(staff.id, date)
      }
    }
    if (key === "paysheet_date") {
      const date = new Date(value as Date)
      const days = getExpectedWorkDays(date.getMonth() + 1, date.getFullYear(), workDays)
      form.setFieldValue("expected_work_days", days)
      if (nameStaff) {
        form.setFieldValue("name", `${nameStaff} - ${date.getMonth() + 1}/${date.getFullYear()}`)
      }
      setDate(date)
      if (staffId) {
        await getCheckIns(staffId, date)
      }
      let advancePayment = 0
      const staff = dataStaffs.find(s => s.id === staffId)
      if (staff && staff.internal_debts && staff.internal_debts?.length > 0) {
        staff.internal_debts.forEach(debt => {
          if (debt.status === StatusEnum.ACTIVE && !debt.settlement) {
            advancePayment += debt.amount
          }
        })
        form.setFieldValue("advance_payment", advancePayment)
      }
      if (days > 0) {
        form.setFields([
          {
            name: "total_work_days",
            errors: [],
          },
        ])
      }
    }

    const basicIncome = form.getFieldValue("basic_income")
    const totalWorkDays = form.getFieldValue("total_work_days")
    const extraIncome = form.getFieldValue("extra_income")
    const negativeIncome = form.getFieldValue("negative_income")
    const advancePayment = form.getFieldValue("advance_payment")
    const expectedWorkDays = form.getFieldValue("expected_work_days")
    if (!basicIncome) return
    const incomeByDay = Math.round((parseFloat(basicIncome) / parseFloat(expectedWorkDays)) * parseFloat(totalWorkDays))
    const totalIncome = Math.round(incomeByDay + parseFloat(extraIncome) - parseFloat(negativeIncome))
    const finalIncome = Math.round(totalIncome - parseFloat(advancePayment))
    form.setFieldValue("income_by_day", isNaN(incomeByDay) ? 0 : incomeByDay)
    form.setFieldValue("total_income", isNaN(totalIncome) ? 0 : totalIncome)
    form.setFieldValue("final_income", isNaN(finalIncome) ? 0 : finalIncome)

    if (form.getFieldValue("final_income") < 0) {
      form.setFields([
        {
          name: "final_income",
          warnings: ["Số lương ứng đang vượt quá số tiền lương thực nhận"],
        },
      ])
    } else {
      form.setFields([
        {
          name: "final_income",
          warnings: [],
        },
      ])
    }

    return Promise.resolve()
  }

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button
            key="cancel"
            onClick={() => {
              props.cancelProps()
              onReset()
            }}
          >
            Hủy
          </Button>
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={handleUpdatePaySheet} loading={buttonLoading}>
              Lưu
            </Button>
          )}
          {props.actionType === "CREATE" && (
            <Button type="primary" onClick={() => handleAddPaySheet()} loading={buttonLoading}>
              Thêm mới
            </Button>
          )}
        </Space>
      </>
    )
  }

  return (
    <Modal
      title={props.actionType === "CREATE" ? "Thêm bảng lương" : "Chỉnh sửa bảng lương"}
      open={props.openProps}
      width={1200}
      onCancel={props.cancelProps}
      footer={renderFooter()}
    >
      <Form
        name="wrap"
        labelCol={{ flex: "180px" }}
        labelAlign="left"
        labelWrap
        colon={false}
        form={form}
        wrapperCol={{ span: 16 }}
        onValuesChange={handleValuesChange}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Tên bảng lương:" name="name">
              <Input placeholder="Nhập tên bảng lương" readOnly disabled />
            </Form.Item>
            <Form.Item
              label="Tên nhân viên:"
              name="staff_id"
              rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
            >
              <Select
                showSearch
                placeholder="Nhập tìm nhân viên"
                optionFilterProp="children"
                filterOption={filterOption}
                options={dataStaffs.map(item => ({
                  value: item.id as string,
                  label: item.name,
                  basic_income: item.basic_income,
                }))}
              />
            </Form.Item>
            <Form.Item label="Tháng tính lương:" name="paysheet_date" initialValue={dayjs(new Date())}>
              <DatePicker picker="month" format={dateFormatListMonth} />
            </Form.Item>
            <Form.Item label="Thưởng:" name="extra_income" initialValue={0}>
              <InputNumber
                style={styles.inputNumber}
                placeholder="Nhập tiền thưởng của nhân viên"
                formatter={value => formatterInputNumber(value)}
                addonAfter="VNĐ"
                step={10000}
                min={0}
              />
            </Form.Item>
            <Form.Item label="Tiền phạt / truy thu:" name="negative_income" initialValue={0}>
              <InputNumber
                style={styles.inputNumber}
                placeholder="Nhập tiền phạt"
                formatter={value => formatterInputNumber(value)}
                addonAfter="VNĐ"
                step={10000}
                min={0}
              />
            </Form.Item>
            <Form.Item label="Lương ứng:" name="advance_payment" initialValue={0}>
              <InputNumber
                style={styles.inputNumber}
                placeholder="Lương ứng theo tháng của nhân viên"
                formatter={value => formatterInputNumber(value)}
                addonAfter="VNĐ"
                disabled
                readOnly
              />
            </Form.Item>

            <Form.Item label="Ngày trả lương:" name="payday" initialValue={dayjs(new Date()).add(1, "month").date(5)}>
              <DatePicker format={dateFormatList} />
            </Form.Item>
            <Form.Item label="Trạng thái" name="has_settle" initialValue={false}>
              <Select
                placeholder="Vui lòng chọn trạng thái"
                style={{ width: "100%" }}
                options={[
                  { value: true, label: "Đã thanh toán" },
                  { value: false, label: "Chưa thanh toán" },
                ]}
                defaultValue={false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Lương cơ bản:" name="basic_income">
              <InputNumber
                placeholder="Nhập lương cơ bản của nhân viên"
                readOnly
                disabled
                style={styles.inputNumber}
                formatter={value => formatterInputNumber(value)}
                addonAfter="VNĐ"
              />
            </Form.Item>
            <Form.Item
              label="Số công làm việc thực tế:"
              name="total_work_days"
              rules={[
                {
                  required: true,
                  message: "Vui lòng thực hiện Chấm công nhân viên trước khi tạo bảng lương.",
                },
              ]}
              extra={
                hasLinkToCheckIn ? (
                  <p>
                    Số ngày làm việc thực tế này được lấy từ <Link to={linkToCheckIn}>Bảng chấm công</Link>
                  </p>
                ) : (
                  <p>Nhân viên này chưa có bảng chấm công trong tháng hoặc chưa duyệt bảng chấm công</p>
                )
              }
            >
              <Input readOnly disabled />
            </Form.Item>

            <Form.Item
              label="Tổng ngày làm việc tối đa trong tháng:"
              name="expected_work_days"
              initialValue={expectedWorkDays}
            >
              <Input readOnly disabled />
            </Form.Item>

            <Form.Item
              label="Lương tính theo ngày công"
              name="income_by_day"
              extra="(Lương cơ bản / Tổng ngày làm việc tối đa trong tháng) x Số công làm việc thực tế)"
            >
              <InputNumber
                style={styles.inputNumber}
                formatter={value => formatterInputNumber(value)}
                addonAfter="VNĐ"
                readOnly
                disabled
              />
            </Form.Item>
            <Form.Item
              label="Tổng lương thực tế:"
              name="total_income"
              extra="(Lương tính theo ngày công + Tiền thưởng - Tiền phạt)"
            >
              <InputNumber
                style={styles.inputNumber}
                formatter={value => formatterInputNumber(value)}
                addonAfter="VNĐ"
                readOnly
                disabled
              />
            </Form.Item>
            <Form.Item
              label="Tiền nhận tại ngày phát lương:"
              name="final_income"
              extra="(Lương tính theo ngày công + Tiền thưởng - Tiền ứng - Tiền phạt)"
            >
              <InputNumber
                style={styles.inputNumber}
                formatter={value => formatterInputNumber(value)}
                addonAfter="VNĐ"
                readOnly
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default UpsertForm

const styles = {
  inputNumber: {
    width: "100%",
  },
}
