import { PayableDebt } from "@/modules/finances/payable_debts/types"
import { Http } from "@/network/http"
import { notificationAction } from "@/utils"
import { Filters, NotificationEnum, StatusEnum } from "@/utils/constants"

export const API_PREFIX_PATH = "/payable-debts"

export const fetchPayableDebts = async (filters?: Filters) => {
  return await Http.get(`${API_PREFIX_PATH}`, { params: filters })
}

export const fetchPayableDebt = async (id: string) => {
  return await Http.get(`${API_PREFIX_PATH}/${id}`)
}

export const createPayableDebt = async (data?: PayableDebt) => {
  return await Http.post(`${API_PREFIX_PATH}`, data)
}

export const updatePayableDebt = async (id: string, data?: PayableDebt) => {
  return await Http.put(`${API_PREFIX_PATH}/${id}`, data)
}

export const deletePayableDebt = async (data: PayableDebt) => {
  try {
    const updatedData = { ...data }
    if (!updatedData.id) return
    const response = await updatePayableDebt(updatedData.id.toString(), { ...updatedData, status: StatusEnum.DELETED })
    if (response.statusCode === 200) {
      notificationAction(NotificationEnum.DELETED, NotificationEnum.SUCCESS)
    }
  } catch (error) {
    console.error("Addition failed:", error)
    notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
  }
}
