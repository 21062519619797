import List from "@/modules/finances/receipts/views/List"
import { UpdateReceipt } from "@/modules/finances/receipts/views/UpdateReceipt"
import { Route, Routes } from "react-router-dom"

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      {/* <Route path="create" element={<Create />} /> */}
      <Route path=":id" element={<UpdateReceipt />} />
    </Routes>
  )
}
