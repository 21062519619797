import { UpdateRole } from "@/modules/manages/users/types"
import { Http } from "@/network/http"
import { Filters } from "@/utils/constants"

export const API_PREFIX_PATH = "/roles"

export const fetchRoles = async (filters?: Filters) => {
  return await Http.get(`${API_PREFIX_PATH}`, { params: filters })
}

export const fetchRole = async (id: string) => {
  return await Http.get(`${API_PREFIX_PATH}/${id}`)
}

export const deleteRole = async (id: string) => {
  return await Http.post(`${API_PREFIX_PATH}/delete/${id}`)
}

export const createUpdateRole = async (data: UpdateRole, id?: string) => {
  const correctUrl = id ? `${API_PREFIX_PATH}/${id}` : `${API_PREFIX_PATH}`
  return await Http.post(correctUrl, data)
}
