import { ReceivableDebt } from "@/modules/finances/receivable_debts/types"
import { Http } from "@/network/http"
import { notificationAction } from "@/utils"
import { Filters, NotificationEnum, StatusEnum } from "@/utils/constants"

export const API_PREFIX_PATH = "/receivable-debts"

export const fetchReceivableDebts = async (filters?: Filters) => {
  return await Http.get(`${API_PREFIX_PATH}`, { params: filters })
}

export const fetchReceivableDebt = async (id: string) => {
  return await Http.get(`${API_PREFIX_PATH}/${id}`)
}

export const createReceivableDebt = async (data?: ReceivableDebt) => {
  return await Http.post(`${API_PREFIX_PATH}`, data)
}

export const updateReceivableDebt = async (id: string, data?: ReceivableDebt) => {
  return await Http.put(`${API_PREFIX_PATH}/${id}`, data)
}

export const deleteReceivableDebt = async (data: ReceivableDebt) => {
  try {
    const updatedData = { ...data }
    if (!updatedData.id) return
    const response = await updateReceivableDebt(updatedData.id.toString(), {
      ...updatedData,
      status: StatusEnum.DELETED,
    })
    if (response.statusCode === 200) {
      notificationAction(NotificationEnum.DELETED, NotificationEnum.SUCCESS)
    }
  } catch (error) {
    console.error("Addition failed:", error)
    notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
  }
}
