import List from "@/modules/settings/receipt-categories/views/List"
import { EditPopup } from "@/modules/settings/receipt-categories/views/EditPopup"
import { Route, Routes } from "react-router-dom"
export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path=":id" element={<EditPopup />} />
      {/* <Route path="create" element={<Create />} /> */}
    </Routes>
  )
}
