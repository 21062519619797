import List from "@/modules/hrs/staffs/views/List"
import { UpsertStaff } from "@/modules/hrs/staffs/views/UpsertStaff"
import { Route, Routes } from "react-router-dom"

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      {/* <Route path="create" element={<Create />} /> */}
      <Route path=":id" element={<UpsertStaff />} />
    </Routes>
  )
}
