/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface PartnersDto {
  name: string
  status: string
}

export interface CustomersDto {
  name: string
  phone_number: string
}

export interface StaffsDto {
  name: string

  /** @format date-time */
  birthday: string
  phone_number: string
  identification_id: string
  hometown: string
  sex: string
  position_id: number
  basic_income: number
}

export interface InternalDebtsDto {
  borrower_id: string
  amount: number
  status: string
  settlement: boolean

  /** @format date-time */
  date_happened: string
  type: string
}

export interface PayableDebtsDto {
  lender_id: string
  amount: number
  settlement: boolean
  status: string

  /** @format date-time */
  due_date: string

  /** @format date-time */
  date_happened: string
}

export interface ReceivableDebtsDto {
  borrower_id: string
  amount: number
  settlement: boolean

  /** @format date-time */
  date_happened: string
  status: string

  /** @format date-time */
  due_date: string
}

export interface ReceiptsDto {
  name: string
  amount: number
  type: string
  category_id: number
  notes: string

  /** @format date-time */
  date_happened: string
}

export interface ReceiptCategoryDto {
  category: string
  type: string
}

export interface PaysheetsDto {
  name: string
  staff_id: number
  total_work_days: number
  basic_income: number
  total_debt: number
  advance_payment: number
  extra_income: number
  negative_income: number
  total_income: number
  final_income: number

  /** @format date-time */
  paysheet_date: string
  has_settle: boolean

  /** @format date-time */
  payday: string
}

export interface UsersDto {
  username: string
  password: string
  phone_number: string
  fullname: string
  role_id: number
  status: string
}

export interface AuthDto {
  username: string
  password: string
}

export interface CheckInDto {
  staff_id: number
  value: string[]
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = ""
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"]
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&")
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter(key => "undefined" !== typeof query[key])
    return keys
      .map(key => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&")
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ""
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async response => {
      const r = response as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then(data => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch(e => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title Finhanh
 * @version 1.0
 * @contact
 *
 * API description
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name AppControllerGetHello
   * @request GET:/
   */
  appControllerGetHello = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/`,
      method: "GET",
      ...params,
    })

  partners = {
    /**
     * No description
     *
     * @tags Partner
     * @name PartnersControllerGetAllPartner
     * @summary Get all partners
     * @request GET:/partners
     */
    partnersControllerGetAllPartner: (
      query?: { search?: string; name?: string; status?: string; created_at?: string; page?: number; limit?: number },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/partners`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Partner
     * @name PartnersControllerCreatePartner
     * @summary Create new partner
     * @request POST:/partners
     */
    partnersControllerCreatePartner: (data: PartnersDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/partners`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Partner
     * @name PartnersControllerGetPartnerById
     * @summary Get partner by id
     * @request GET:/partners/{id}
     */
    partnersControllerGetPartnerById: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/partners/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Partner
     * @name PartnersControllerUpdatePartner
     * @summary Update partner by ID
     * @request PUT:/partners/{id}
     */
    partnersControllerUpdatePartner: (id: number, data: PartnersDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/partners/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  customers = {
    /**
     * No description
     *
     * @tags Customer
     * @name CustomersControllerGetAllCustomers
     * @summary Get all customers
     * @request GET:/customers
     */
    customersControllerGetAllCustomers: (
      query?: {
        search?: string
        name?: string
        phone_number?: string
        created_at?: string
        page?: number
        limit?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/customers`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomersControllerCreateCustomer
     * @summary Create new customer
     * @request POST:/customers
     */
    customersControllerCreateCustomer: (data: CustomersDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/customers`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomersControllerGetCustomerById
     * @summary Get customer by id
     * @request GET:/customers/{id}
     */
    customersControllerGetCustomerById: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/customers/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomersControllerUpdateCustomer
     * @summary Update customer by ID
     * @request PUT:/customers/{id}
     */
    customersControllerUpdateCustomer: (id: number, data: CustomersDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/customers/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  staffs = {
    /**
     * No description
     *
     * @tags Staff
     * @name StaffsControllerGetAllStaffs
     * @summary Get all staffs
     * @request GET:/staffs
     */
    staffsControllerGetAllStaffs: (
      query?: {
        search?: string
        name?: string
        birthday?: string
        hometown?: string
        position_id?: number
        basic_income?: string
        status?: string
        created_at?: string
        sex?: string
        page?: number
        limit?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/staffs`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Staff
     * @name StaffsControllerCreateStaff
     * @summary Create new staff
     * @request POST:/staffs
     */
    staffsControllerCreateStaff: (data: StaffsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/staffs`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Staff
     * @name StaffsControllerGetStaffById
     * @summary Get staff by id
     * @request GET:/staffs/{id}
     */
    staffsControllerGetStaffById: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/staffs/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Staff
     * @name StaffsControllerUpdateStaff
     * @summary Update staff by ID
     * @request PUT:/staffs/{id}
     */
    staffsControllerUpdateStaff: (id: number, data: StaffsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/staffs/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  internalDebts = {
    /**
     * No description
     *
     * @tags Internal Debt
     * @name InternalDebtsControllerGetAllInternalDebts
     * @summary Get all internal debts
     * @request GET:/internal-debts
     */
    internalDebtsControllerGetAllInternalDebts: (
      query?: {
        search?: string
        amount?: string
        created_at?: string
        status?: string
        settlement?: boolean
        date_happened?: string
        type?: string
        page?: number
        limit?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/internal-debts`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal Debt
     * @name InternalDebtsControllerCreateInternalDebt
     * @summary Create new internal debt
     * @request POST:/internal-debts
     */
    internalDebtsControllerCreateInternalDebt: (data: InternalDebtsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/internal-debts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal Debt
     * @name InternalDebtsControllerGetInternalDebtById
     * @summary Get internal debt by id
     * @request GET:/internal-debts/{id}
     */
    internalDebtsControllerGetInternalDebtById: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/internal-debts/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal Debt
     * @name InternalDebtsControllerUpdateInternalDebt
     * @summary Update internal debt by ID
     * @request PUT:/internal-debts/{id}
     */
    internalDebtsControllerUpdateInternalDebt: (id: number, data: InternalDebtsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/internal-debts/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  payableDebts = {
    /**
     * No description
     *
     * @tags Payable Debt
     * @name PayableDebtsControllerGetAllPayableDebts
     * @summary Get all payable debts
     * @request GET:/payable-debts
     */
    payableDebtsControllerGetAllPayableDebts: (
      query?: {
        search?: string
        amount?: string
        created_at?: string
        due_date?: string
        status?: string
        settlement?: boolean
        date_happened?: string
        page?: number
        limit?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/payable-debts`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payable Debt
     * @name PayableDebtsControllerCreatePayableDebt
     * @summary Create new payable debt
     * @request POST:/payable-debts
     */
    payableDebtsControllerCreatePayableDebt: (data: PayableDebtsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payable-debts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payable Debt
     * @name PayableDebtsControllerGetPayableDebtById
     * @summary Get payable debt by id
     * @request GET:/payable-debts/{id}
     */
    payableDebtsControllerGetPayableDebtById: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payable-debts/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payable Debt
     * @name PayableDebtsControllerUpdatePayableDebt
     * @summary Update payable debt by ID
     * @request PUT:/payable-debts/{id}
     */
    payableDebtsControllerUpdatePayableDebt: (id: number, data: PayableDebtsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payable-debts/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  receivableDebts = {
    /**
     * No description
     *
     * @tags Receivable Debt
     * @name ReceivableDebtsControllerGetAllReceivableDebts
     * @summary Get all receivable debts
     * @request GET:/receivable-debts
     */
    receivableDebtsControllerGetAllReceivableDebts: (
      query?: {
        search?: string
        borrower_id?: string
        amount?: string
        created_at?: string
        due_date?: string
        status?: string
        settlement?: boolean
        date_happened?: string
        page?: number
        limit?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/receivable-debts`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receivable Debt
     * @name ReceivableDebtsControllerCreateReceivableDebt
     * @summary Create new receivable debt
     * @request POST:/receivable-debts
     */
    receivableDebtsControllerCreateReceivableDebt: (data: ReceivableDebtsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/receivable-debts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receivable Debt
     * @name ReceivableDebtsControllerGetReceivableDebtById
     * @summary Get receivable debt by id
     * @request GET:/receivable-debts/{id}
     */
    receivableDebtsControllerGetReceivableDebtById: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/receivable-debts/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receivable Debt
     * @name ReceivableDebtsControllerUpdateReceivableDebt
     * @summary Update receivable debt by ID
     * @request PUT:/receivable-debts/{id}
     */
    receivableDebtsControllerUpdateReceivableDebt: (id: number, data: ReceivableDebtsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/receivable-debts/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  receipts = {
    /**
     * No description
     *
     * @tags Receipts
     * @name ReceiptsControllerGetAllReceipts
     * @summary Get all receipts
     * @request GET:/receipts
     */
    receiptsControllerGetAllReceipts: (
      query?: {
        search?: string
        name?: string
        amount?: number
        type?: string
        created_at?: string
        category_id?: number
        date_happened?: string
        page?: number
        limit?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/receipts`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receipts
     * @name ReceiptsControllerCreatePartner
     * @summary Create new receipt
     * @request POST:/receipts
     */
    receiptsControllerCreatePartner: (data: ReceiptsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/receipts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receipts
     * @name ReceiptsControllerGetPartner
     * @summary Get receipt by id
     * @request GET:/receipts/{id}
     */
    receiptsControllerGetPartner: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/receipts/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receipts
     * @name ReceiptsControllerUpdatePartner
     * @summary Update receipt by ID
     * @request PUT:/receipts/{id}
     */
    receiptsControllerUpdatePartner: (id: number, data: ReceiptsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/receipts/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  receiptCategories = {
    /**
     * No description
     *
     * @tags Receipt Category
     * @name ReceiptCategoryControllerGetAllReceiptCategories
     * @summary Get all receipt category
     * @request GET:/receipt-categories
     */
    receiptCategoryControllerGetAllReceiptCategories: (
      query?: {
        search?: string
        category?: string
        type?: string
        created_at?: string
        created_by_id?: number
        page?: number
        limit?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/receipt-categories`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receipt Category
     * @name ReceiptCategoryControllerCreateReceiptCategory
     * @summary Create new receipt category.
     * @request POST:/receipt-categories
     */
    receiptCategoryControllerCreateReceiptCategory: (data: ReceiptCategoryDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/receipt-categories`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receipt Category
     * @name ReceiptCategoryControllerGetReceiptCategoryById
     * @summary Get receipt category by id
     * @request GET:/receipt-categories/{id}
     */
    receiptCategoryControllerGetReceiptCategoryById: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/receipt-categories/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receipt Category
     * @name ReceiptCategoryControllerUpdateReceiptCategory
     * @summary Update receipt category by ID
     * @request PUT:/receipt-categories/{id}
     */
    receiptCategoryControllerUpdateReceiptCategory: (
      id: number,
      data: ReceiptCategoryDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/receipt-categories/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  paysheets = {
    /**
     * No description
     *
     * @tags Paysheets
     * @name PaysheetsControllerGetAllPaysheet
     * @summary Get all paysheets
     * @request GET:/paysheets
     */
    paysheetsControllerGetAllPaysheet: (
      query?: {
        search?: string
        name?: string
        staff_id?: number
        total_work_days?: string
        basic_income?: string
        total_debt?: string
        advance_payment?: string
        extra_income?: string
        negative_income?: string
        total_income?: string
        final_income?: string
        created_at?: string
        paysheet_date?: string
        has_settle?: boolean
        payday?: string
        page?: number
        limit?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/paysheets`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Paysheets
     * @name PaysheetsControllercreatePaySheet
     * @summary Create new paysheet.
     * @request POST:/paysheets
     */
    paysheetsControllercreatePaySheet: (data: PaysheetsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/paysheets`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Paysheets
     * @name PaysheetsControllerGetPaysheet
     * @summary Get paysheet by id
     * @request GET:/paysheets/{id}
     */
    paysheetsControllerGetPaysheet: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/paysheets/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Paysheets
     * @name PaysheetsControllerupdatePaySheet
     * @summary Update paysheet by ID
     * @request PUT:/paysheets/{id}
     */
    paysheetsControllerupdatePaySheet: (id: number, data: PaysheetsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/paysheets/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  roles = {
    /**
     * No description
     *
     * @tags Roles
     * @name RolesControllerGetAllRoles
     * @summary Get all roles
     * @request GET:/roles
     */
    rolesControllerGetAllRoles: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/roles`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Roles
     * @name RolesControllerGetRole
     * @summary Get role by ID
     * @request GET:/roles/{id}
     */
    rolesControllerGetRole: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/roles/${id}`,
        method: "GET",
        ...params,
      }),
  }
  permissions = {
    /**
     * No description
     *
     * @tags Permissions
     * @name PermissionsControllerGetAllRoles
     * @summary Get all permissions
     * @request GET:/permissions
     */
    permissionsControllerGetAllRoles: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/permissions`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permissions
     * @name PermissionsControllerGetRole
     * @summary Get permission by ID
     * @request GET:/permissions/{id}
     */
    permissionsControllerGetRole: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/permissions/${id}`,
        method: "GET",
        ...params,
      }),
  }
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerGetAllUsers
     * @summary Get all users
     * @request GET:/users
     */
    usersControllerGetAllUsers: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerCreateUser
     * @summary Create new user
     * @request POST:/users
     */
    usersControllerCreateUser: (data: UsersDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerGetRole
     * @summary Get user by username
     * @request GET:/users/{username}
     */
    usersControllerGetRole: (username: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${username}`,
        method: "GET",
        ...params,
      }),
  }
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSignIn
     * @summary Sign in
     * @request POST:/auth/sign-in
     */
    authControllerSignIn: (data: AuthDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/sign-in`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  positions = {
    /**
     * No description
     *
     * @tags Position
     * @name PositionControllerGetAllStaffs
     * @summary Get all positions
     * @request GET:/positions
     */
    positionControllerGetAllStaffs: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/positions`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Position
     * @name PositionControllerGetStaffById
     * @summary Get position by id
     * @request GET:/positions/{id}
     */
    positionControllerGetStaffById: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/positions/${id}`,
        method: "GET",
        ...params,
      }),
  }
  checkIn = {
    /**
     * No description
     *
     * @tags Check in
     * @name CheckInControllerGetCheckInData
     * @summary Get check in data by staff id
     * @request GET:/check-in/{staffId}
     */
    checkInControllerGetCheckInData: (staffId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/check-in/${staffId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Check in
     * @name CheckInControllerUpdateCheckInData
     * @summary Create or update check in data of a staff
     * @request POST:/check-in
     */
    checkInControllerUpdateCheckInData: (data: CheckInDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/check-in`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
}
