import { onHttpRequestError } from "@/network/http/onHttpRequestError"
import axios, { AxiosRequestHeaders } from "axios"
import { Api } from "../../__generated__/api-v1"
import { configs } from "../../configs"
import { ACCESS_TOKEN } from "../../constants"

axios.defaults.baseURL = configs.API_URL

export const getToken = () => {
  try {
    const token = localStorage.getItem(ACCESS_TOKEN)
    if (token) {
      return JSON.parse(token)
    }
    return null
  } catch (ex) {
    return null
  }
}

const withAuth = (headers: AxiosRequestHeaders = {}): AxiosRequestHeaders => {
  return {
    ...headers,
    Authorization: `Bearer ${getToken()}`,
  }
}

const catchTransactionError = async (asyncFunc: any, apiName = "") => {
  try {
    const response = await asyncFunc
    return response ? response.data : null
  } catch (error: any) {
    let response = {
      data: { data: null, error: null },
      message: error.message,
    }
    if (error.response) {
      response = { ...error.response, message: error.message }
    }
    onHttpRequestError(response, apiName)
  }
}

interface ConfigsProps {
  headers?: AxiosRequestHeaders
  apiName?: string
  params?: object
  data?: object
}

export const getRequest = async (url: string, configs: ConfigsProps = {}) =>
  catchTransactionError(
    axios.get(url, {
      // @ts-ignore
      headers: withAuth(configs.headers),
      ...configs,
    }),
    configs.apiName,
  )

export const postRequest = async (url: string, data: any = null, configs: ConfigsProps = {}) =>
  catchTransactionError(
    axios.post(url, data, {
      headers: withAuth(configs.headers),
      ...configs,
    }),
    configs.apiName,
  )

export const putRequest = async (url: string, data: any, configs: ConfigsProps = {}) =>
  catchTransactionError(
    axios.put(url, data, {
      // @ts-ignore
      headers: withAuth(configs.headers),
      ...configs,
    }),
    configs.apiName,
  )

export const patchRequest = async (url: string, data: any, configs: ConfigsProps = {}) =>
  catchTransactionError(
    axios.patch(url, data, {
      // @ts-ignore
      headers: withAuth(configs.headers),
      ...configs,
    }),
    configs.apiName,
  )

export const deleteRequest = async (url: string, configs: ConfigsProps = {}) =>
  catchTransactionError(
    axios.delete(url, {
      // @ts-ignore
      headers: withAuth(configs.headers),
      ...configs,
    }),
    configs.apiName,
  )

export const API = new Api({
  baseUrl: configs.API_URL,
  baseApiParams: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
    },
  },
})

export class Http {
  static get = getRequest
  static post = postRequest
  static put = putRequest
  static patch = patchRequest
  static delete = deleteRequest
}
