import { fetchRoles } from "@/modules/manages/users/services/roles.services"
import { createUser, fetchUser, updateUser } from "@/modules/manages/users/services/users.services"
import { Role, UpdateUserData, User } from "@/modules/manages/users/types"
import { NotificationEnum, StatusEnum } from "@/utils/constants"
import { notificationAction } from "@/utils/helpers"
import { Button, Form, Input, Modal, Select, Space } from "antd"
import { useEffect, useState } from "react"

interface BaseUpsertForm {
  openProps?: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: string
  idEdit?: string
}

const UpsertForm: React.FC<BaseUpsertForm> = props => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [itemRoles, setItemRoles] = useState<Role[]>([])
  const [selectedUserId, setSelectedUserId] = useState<number>(0)

  const onReset = () => {
    form.resetFields()
  }

  const handleAddUser = async () => {
    try {
      const values = await form.validateFields()
      const newData: User = {
        username: values.username,
        password: values.password,
        phone_number: values.phone_number,
        fullname: values.fullname,
        role_id: values.role_id,
        status: values.status,
      }
      setLoading(true)
      const response = await createUser(newData)

      if (response.statusCode === 200) {
        setLoading(false)
        onReset()
        props.cancelProps()
        props.refreshProps()
        notificationAction(NotificationEnum.CREATED, NotificationEnum.SUCCESS)
      } else {
        setLoading(false)
        form.setFields([
          {
            name: "username",
            errors: [response.message],
          },
        ])
      }

      return Promise.resolve()
    } catch (error) {
      console.error("Addition failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  useEffect(() => {
    if (props.actionType === "CREATE") {
      form.resetFields()
    }

    if (props.actionType === "UPDATE" && props.idEdit) {
      fetchUser(props.idEdit).then(res => {
        setSelectedUserId(res.data.id)
        form.setFieldsValue({
          username: res.data.username,
          phone_number: res.data.phone_number,
          fullname: res.data.fullname,
          role_id: res.data.role_id,
          status: res.data.status,
        })
      })
    }
  }, [props.actionType, props.idEdit])

  const handleUpdateUser = async () => {
    try {
      if (!selectedUserId) {
        throw new Error("Không tìm thấy user")
      }
      if (props.idEdit) {
        const values = await form.validateFields()
        const editData: UpdateUserData = {
          username: values.username,
          status: values.status,
          phone_number: values.phone_number,
          fullname: values.fullname,
          role_id: values.role_id,
        }
        if (values.password) {
          editData.password = values.password
        }
        setLoading(true)
        const response = await updateUser(`${selectedUserId}`, editData)
        if (response.statusCode === 200) {
          setLoading(false)
          props.cancelProps()
          props.refreshProps()
          notificationAction(NotificationEnum.UPDATED, NotificationEnum.SUCCESS)
        }
      }

      return Promise.resolve()
    } catch (error) {
      console.error("Edit failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  useEffect(() => {
    fetchRoles().then(res => {
      setItemRoles(res.data.items)
    })
  }, [])

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button
            key="cancel"
            onClick={() => {
              props.cancelProps()
              onReset()
            }}
          >
            Hủy
          </Button>
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={handleUpdateUser} loading={loading}>
              Lưu
            </Button>
          )}
          {props.actionType === "CREATE" && (
            <Button type="primary" onClick={() => handleAddUser()} loading={loading}>
              Thêm mới
            </Button>
          )}
        </Space>
      </>
    )
  }

  return (
    <Modal
      title={props.actionType === "CREATE" ? "Thêm tài khoản" : "Chỉnh sửa tài khoản"}
      open={props.openProps}
      width={600}
      onCancel={props.cancelProps}
      footer={renderFooter()}
    >
      <Form
        name="wrap"
        labelCol={{ flex: "110px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        style={{ maxWidth: 600 }}
        form={form}
      >
        <Form.Item
          label="Tên tài khoản"
          name="username"
          rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
        >
          <Input placeholder="Nhập họ và tên" />
        </Form.Item>

        <Form.Item
          label="Mật khẩu"
          name="password"
          rules={[
            { required: props.actionType === "CREATE", message: "Vui lòng nhập mật khẩu!" },
            {
              min: 8,
              message: "Mật khẩu phải trên 8 kí tự!",
            },
          ]}
        >
          <Input.Password placeholder="Nhập mật khẩu" visibilityToggle={true} />
        </Form.Item>

        <Form.Item
          label="Họ và tên"
          name="fullname"
          rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
        >
          <Input placeholder="Nhập tên tài khoản" />
        </Form.Item>

        <Form.Item
          label="Số điện thoại"
          name="phone_number"
          rules={[{ required: true, pattern: new RegExp(/^[0-9]+$/), message: "Vui lòng chỉ nhập số!" }]}
        >
          <Input placeholder="Nhập số điện thoại" />
        </Form.Item>

        <Form.Item label="Vai trò" name="role_id" rules={[{ required: true, message: "Vui lòng chọn vai trò!" }]}>
          <Select
            placeholder="Vui lòng chọn vai trò"
            style={{ width: "100%" }}
            options={itemRoles.map(items => ({
              value: items.id,
              label: items.name,
            }))}
          />
        </Form.Item>

        <Form.Item label="Trạng thái" name="status" initialValue={StatusEnum.ACTIVE}>
          <Select
            placeholder="Vui lòng chọn trạng thái"
            style={{ width: "100%" }}
            options={[
              { value: StatusEnum.ACTIVE, label: "Hoạt động" },
              { value: StatusEnum.DISABLED, label: "Không hoạt động" },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default UpsertForm
