import { CheckInRouter } from "@/modules/hrs/check_ins"
import { InternalDebtsRouter } from "@/modules/hrs/internal_debts"
import { PaysheetsRouter } from "@/modules/hrs/paysheets"
import { StaffsRouter } from "@/modules/hrs/staffs"
import { Route, Routes } from "react-router-dom"

export const HrsRouter = () => {
  return (
    <Routes>
      <Route path="staffs/*" element={<StaffsRouter />} />
      <Route path="internal-debts/*" element={<InternalDebtsRouter />} />
      <Route path="paysheets/*" element={<PaysheetsRouter />} />
      <Route path="checkin/*" element={<CheckInRouter />} />
    </Routes>
  )
}
