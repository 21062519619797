import { fetchPermissionsByRouteName } from "@/modules/manages/permissions/services/permissions.services"
import { MAP_PERMISSIONS, PermissionsEnum } from "@/modules/manages/roles/types"
import { Checkbox } from "antd"
import { useEffect, useState } from "react"

interface Permission {
  route_name: string
  permissions: { id: number; type: string }[]
}

interface Props {
  data: number[]
  handleCheck: (checked: boolean, value: number) => void
}

const PermissionList: React.FC<Props> = ({ data, handleCheck }: Props) => {
  const [permissions, setPermissions] = useState<Permission[]>([])

  useEffect(() => {
    async function init() {
      const permissions = await fetchPermissionsByRouteName()
      setPermissions(permissions.data)
    }

    init()
  }, [data])

  return (
    <div>
      {permissions.length > 0 &&
        permissions.map((permission: Permission, i: number) => (
          <div key={permission.route_name}>
            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: 8, marginTop: i === 0 ? 0 : 8 }}>
              Quyền ở mục {permission.route_name}
            </p>
            {permission.permissions.map((item: { id: number; type: string }) => (
              <Checkbox
                key={item.id}
                checked={checkPermission(data, item.id)}
                onChange={e => handleCheck(e.target.checked, item.id)}
              >
                {MAP_PERMISSIONS[item.type as PermissionsEnum]}
              </Checkbox>
            ))}
          </div>
        ))}
    </div>
  )
}

function checkPermission(rolePermissions: number[], permission: number) {
  const isHavePermission = rolePermissions.includes(permission)
  return isHavePermission
}

export default PermissionList
