import {
  createPayableDebt,
  fetchPayableDebt,
  updatePayableDebt,
} from "@/modules/finances/payable_debts/services/payabledebts.services"
import { PayableDebt } from "@/modules/finances/payable_debts/types"
import { Partner } from "@/modules/partners"
import { fetchPartners } from "@/modules/partners/services/partner.services"
import UpsertFormPartner from "@/modules/partners/view/UpsertForm"
import {
  dateFormatList,
  filterOption,
  formatterInputNumber,
  notificationAction,
  notificationCreateSuccess,
  notificationUpdateSuccess,
} from "@/utils"
import { NotificationEnum, StatusEnum } from "@/utils/constants"
import { PlusOutlined } from "@ant-design/icons"
import { Button, DatePicker, Divider, Form, InputNumber, Modal, Select, Space } from "antd"
import TextArea from "antd/es/input/TextArea"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
interface BaseUpsertForm {
  openProps?: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: string
  idEdit?: string
}

const UpsertForm: React.FC<BaseUpsertForm> = props => {
  const [form] = Form.useForm()
  const [dataPartners, setDataPartners] = useState<Partner[]>([])
  const [isOpenModelAdd, setIsOpenModelAdd] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const onReset = () => {
    form.resetFields()
  }

  const handleCompleteForm = () => {
    onReset()
    props.refreshProps()
    props.cancelProps()
    props.idEdit ? notificationUpdateSuccess() : notificationCreateSuccess()
    setButtonLoading(false)
  }

  const handleAddPayableDebt = async () => {
    try {
      const values = await form.validateFields()
      const newData: PayableDebt = {
        ...values,
        status: StatusEnum.ACTIVE,
      }
      setButtonLoading(true)
      const response = await createPayableDebt(newData)
      if (response.statusCode === 200) {
        handleCompleteForm()
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Addition failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  useEffect(() => {
    if (props.actionType === "CREATE") {
      form.resetFields()
    }

    const onInitEdit = async (idEdit: string) => {
      const debt = await fetchPayableDebt(idEdit)

      form.setFieldsValue({
        lender_id: debt.data.lender_id,
        amount: debt.data.amount,
        settlement: debt.data.settlement,
        date_happened: debt.data.date_happened ? dayjs(debt.data.date_happened) : dayjs(new Date()),
        due_date: debt.data.due_date ? dayjs(debt.data.due_date) : dayjs(new Date()),
        note: debt.data.note ?? "",
      })
    }

    if (props.idEdit) {
      onInitEdit(props.idEdit)
    }
  }, [props.actionType, props.idEdit])

  const handleUpdatePayableDebt = async () => {
    try {
      if (props.idEdit) {
        const values = await form.validateFields()
        const editData = {
          ...values,
          status: StatusEnum.ACTIVE,
        }
        setButtonLoading(true)
        const response = await updatePayableDebt(props.idEdit, editData)
        if (response.statusCode === 200) {
          handleCompleteForm()
        }
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Edit failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  const getPartners = async () => {
    try {
      const response = await fetchPartners()
      if (response?.data) {
        setDataPartners(response.data.items)
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  useEffect(() => {
    getPartners()
  }, [isOpenModelAdd])

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button
            key="cancel"
            onClick={() => {
              props.cancelProps()
              onReset()
            }}
          >
            Hủy
          </Button>
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={handleUpdatePayableDebt} loading={buttonLoading}>
              Lưu
            </Button>
          )}
          {props.actionType === "CREATE" && (
            <Button type="primary" onClick={() => handleAddPayableDebt()} loading={buttonLoading}>
              Thêm mới
            </Button>
          )}
        </Space>
      </>
    )
  }

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const key = Object.keys(changedValues)[0]
    const value = Object.values(changedValues)[0]
    if (key === "date_happened") {
      const date = new Date(value as Date)
      form.setFieldValue("due_date", dayjs(date).add(15, "day"))
    }
  }

  return (
    <>
      <Modal
        title={props.actionType === "CREATE" ? "Thêm công nợ phải trả" : "Chỉnh sửa công nợ phải trả"}
        open={!isOpenModelAdd && props.openProps}
        width={600}
        onCancel={props.cancelProps}
        footer={renderFooter()}
      >
        <Form
          name="wrap"
          labelCol={{ flex: "110px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          form={form}
          onValuesChange={handleValuesChange}
        >
          <Form.Item
            label="Tên đối tác"
            name="lender_id"
            rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
          >
            <Select
              showSearch
              placeholder="Nhập tìm đối tác"
              optionFilterProp="children"
              filterOption={filterOption}
              options={dataPartners.map(item => ({
                value: item.id as string,
                label: item.name,
              }))}
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsOpenModelAdd(true)}>
                      Thêm đối tác mới
                    </Button>
                  </Space>
                </>
              )}
            />
          </Form.Item>

          <Form.Item label="Tiền" name="amount" rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}>
            <InputNumber
              style={styles.inputNumber}
              placeholder="Nhập tiền"
              formatter={value => formatterInputNumber(value)}
              addonAfter="VNĐ"
              step={10000}
              min={0}
            />
          </Form.Item>

          <Form.Item label="Trạng thái" name="settlement" initialValue={false}>
            <Select
              placeholder="Vui lòng chọn trạng thái"
              options={[
                { value: true, label: "Đã thanh toán" },
                { value: false, label: "Chưa thanh toán" },
              ]}
              defaultValue={false}
            />
          </Form.Item>
          <Form.Item label="Ngày mượn" name="date_happened" initialValue={dayjs(new Date())}>
            <DatePicker placeholder="Chọn ngày" format={dateFormatList} />
          </Form.Item>
          <Form.Item label="Ngày tới hạn" name="due_date" initialValue={dayjs(new Date()).add(15, "days")}>
            <DatePicker placeholder="Chọn ngày" format={dateFormatList} />
          </Form.Item>
          <Form.Item label="Ghi chú" name="note">
            <TextArea placeholder="Ghi chú cho khoản nợ" />
          </Form.Item>
        </Form>
      </Modal>
      <UpsertFormPartner
        key="CREATE"
        actionType="CREATE"
        cancelProps={() => setIsOpenModelAdd(false)}
        openProps={isOpenModelAdd}
        refreshProps={() => true}
      />
    </>
  )
}
export default UpsertForm
const styles = {
  inputNumber: {
    width: "350px",
  },
}
