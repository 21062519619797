import { fetchPosition } from "@/modules/hrs/staffs/services/positions.services"
import { createStaff, fetchStaff, updateStaff } from "@/modules/hrs/staffs/services/staff.services"
import { PositionStaffType } from "@/modules/hrs/staffs/types"
import {
  dateFormatList,
  filterOption,
  formatterInputNumber,
  notificationAction,
  notificationCreateSuccess,
  notificationUpdateSuccess,
} from "@/utils"
import { NotificationEnum, StatusEnum } from "@/utils/constants"
import { Button, DatePicker, Form, Input, InputNumber, Modal, Select, Space } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"

interface UpsertFormProps {
  openProps: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: string
  idEdit?: string
}
const UpsertForm: React.FC<UpsertFormProps> = props => {
  const [form] = Form.useForm()
  const [dataPosition, setDataPosition] = useState<PositionStaffType[]>([])
  const [datePickerStatus, setDatePickerStatus] = useState(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const onReset = () => {
    setDatePickerStatus(false)
    form.resetFields()
  }
  useEffect(() => {
    if (props.actionType === "CREATE") {
      form.resetFields()
    }
  }, [props.actionType])

  const handleCompleteForm = () => {
    onReset()
    props.refreshProps()
    props.cancelProps()
    props.idEdit ? notificationUpdateSuccess() : notificationCreateSuccess()
    setButtonLoading(false)
  }

  const handleAddStaff = async () => {
    try {
      const values = await form.validateFields()
      setButtonLoading(true)
      const response = await createStaff({ ...values })
      if (response.statusCode === 200) {
        handleCompleteForm()
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Addition failed:", error)
      notificationAction(`Lỗi`, NotificationEnum.ERROR)
    }
  }

  useEffect(() => {
    const onInitEdit = async (idEdit: string) => {
      const staff = await fetchStaff(idEdit)

      form.setFieldsValue({
        name: staff.data.name,
        phone_number: staff.data.phone_number,
        identification_id: staff.data.identification_id,
        position_id: staff.data.position_id,
        birthday: staff.data.birthday && dayjs(staff.data.birthday),
        hometown: staff.data.hometown,
        sex: staff.data.sex,
        basic_income: staff.data.basic_income,
      })
    }

    if (props.idEdit) {
      onInitEdit(props.idEdit)
    }
  }, [props.idEdit])

  const handleUpdateStaff = async () => {
    try {
      const values = await form.validateFields()
      if (props.idEdit) {
        setButtonLoading(true)
        const response = await updateStaff(props.idEdit, { ...values })
        if (response.statusCode === 200) {
          handleCompleteForm()
        }
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Edit failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  const handleFirstOpenDatePicker = (idEdit?: string) => {
    if (datePickerStatus === false && !idEdit) {
      form.setFieldsValue({
        birthday: dayjs(new Date()).subtract(20, "year").month(0).date(1),
      })
    }
  }

  const getPositions = async () => {
    try {
      const response = await fetchPosition()
      if (response.statusCode === 200) {
        setDataPosition(response.data)
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  useEffect(() => {
    getPositions()
  }, [])

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button
            key="cancel"
            onClick={() => {
              props.cancelProps()
              onReset()
            }}
          >
            Hủy
          </Button>
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={handleUpdateStaff} loading={buttonLoading}>
              Lưu
            </Button>
          )}
          {props.actionType === "CREATE" && (
            <Button
              type="primary"
              onClick={() => {
                handleAddStaff()
              }}
              loading={buttonLoading}
            >
              Thêm mới
            </Button>
          )}
        </Space>
      </>
    )
  }

  return (
    <Modal
      title={props.actionType === "CREATE" ? "Thêm nhân viên" : "Chỉnh sửa nhân viên"}
      open={props.openProps}
      width={600}
      onCancel={props.cancelProps}
      footer={renderFooter()}
    >
      <Form
        name="wrap"
        labelCol={{ flex: "120px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        form={form}
      >
        <Form.Item
          label="Tên nhân viên:"
          name="name"
          rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
        >
          <Input placeholder="Nhập tên nhân viên" />
        </Form.Item>
        <Form.Item
          label="SĐT:"
          name="phone_number"
          rules={[{ required: true, pattern: new RegExp(/^[0-9]+$/), message: "Vui lòng nhập dữ liệu số!" }]}
        >
          <Input placeholder="Nhập số điện thoại nhân viên" />
        </Form.Item>
        <Form.Item label="CCCD/CMND:" name="identification_id">
          <Input placeholder="Nhập CCCD/CMND nhân viên" />
        </Form.Item>
        <Form.Item label="Ngày sinh:" name="birthday">
          <DatePicker
            format={dateFormatList}
            onChange={() => setDatePickerStatus(true)}
            onClick={() => handleFirstOpenDatePicker(props.idEdit)}
          />
        </Form.Item>
        <Form.Item label="Quê quán:" name="hometown">
          <Input placeholder="Nhập quê quán nhân viên" />
        </Form.Item>
        <Form.Item label="Giới tính" name="sex">
          <Select
            placeholder="Vui lòng chọn giới tính"
            options={[
              { value: "male", label: "Nam" },
              { value: "female", label: "Nữ" },
              { value: "other", label: "Khác" },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Loại chức vụ"
          name="position_id"
          rules={[{ required: true, message: "Vui lòng chọn chức vụ!" }]}
        >
          <Select
            placeholder="Vui lòng chọn loại chức vụ"
            filterOption={filterOption}
            optionFilterProp="children"
            showSearch
            options={dataPosition && dataPosition.map(item => ({ value: item?.id, label: item?.name }))}
          />
        </Form.Item>
        <Form.Item
          label="Lương cơ bản:"
          name="basic_income"
          rules={[{ required: true, pattern: new RegExp(/^[0-9]+$/), message: "Vui lòng nhập dữ số liệu vào!" }]}
        >
          <InputNumber
            style={styles.inputNumber}
            placeholder="Nhập lương cơ bản nhân viên"
            formatter={value => formatterInputNumber(value)}
            addonAfter="VNĐ"
            step={10000}
            min={0}
          />
        </Form.Item>
        <Form.Item label="Trạng thái" name="status" initialValue={StatusEnum.ACTIVE}>
          <Select
            placeholder="Vui lòng chọn trạng thái"
            style={{ width: "100%" }}
            options={[
              { value: StatusEnum.ACTIVE, label: "Hoạt động" },
              { value: StatusEnum.DISABLED, label: "Không hoạt động" },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UpsertForm

const styles = {
  inputNumber: {
    width: "100%",
  },
}
