import { CheckInData } from "@/modules/hrs/check_ins/types"
import { Http } from "@/network/http"
import { notificationAction } from "@/utils"
import { Filters, NotificationEnum, StatusEnum } from "@/utils/constants"

export const API_PREFIX_PATH = "/check-in"

export const fetchCheckIn = async (filters?: Filters) => {
  return await Http.get(`${API_PREFIX_PATH}`, { params: filters })
}

export const fetchCheckInById = async (id: string) => {
  return await Http.get(`${API_PREFIX_PATH}/${id}`)
}

export const createCheckIn = async (data: {
  staff_id: string
  value: { date: Date | string; value: number }[]
  month: Date | string
}) => {
  return await Http.post(`${API_PREFIX_PATH}`, data)
}

export const fetchStaffCheckIn = async (staffId: string, month: Date | string) => {
  return await Http.get(`${API_PREFIX_PATH}/staff-check-in/${staffId}`, { params: { month } })
}

export const updateCheckIn = async (
  id: string,
  data: {
    staff_id?: string
    value?: { date: Date | string; value: number }[]
    month?: Date | string
    status?: string
    settlement?: boolean
  },
) => {
  return await Http.put(`${API_PREFIX_PATH}/${id}`, data)
}

export const deleteCheckIn = async (data: CheckInData) => {
  try {
    const updatedData = { ...data }
    if (!updatedData.id) return
    const response = await updateCheckIn(updatedData.id, { ...updatedData, status: StatusEnum.DELETED })
    if (response.statusCode === 200) {
      notificationAction(NotificationEnum.DELETED, NotificationEnum.SUCCESS)
    }
  } catch (error) {
    console.error("Addition failed:", error)
    notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
  }
}

export const createMassCheckIn = async (month: Date | string) => {
  return await Http.post(`${API_PREFIX_PATH}/create-mass`, { month })
}
