import { notification } from "antd"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"

export const Notifications = () => {
  const { title, message, isNoti, type } = useSelector((state: RootState) => state.common.notification)

  useEffect(() => {
    // notification.open({ type: "error", message: "Do not engage", duration: 2 })
    if (message || title) {
      notification.open({ message, duration: 3, type })
    }
  }, [isNoti])

  return null
}
