import Filters, { FilterComponent } from "@/components/Filters"
import { deleteReceipt, fetchReceipts } from "@/modules/finances/receipts/services/receipts.services"
import { MAP_TYPE_RECEIPTS, Receipt, TypeReceipts } from "@/modules/finances/receipts/types"
import UpsertForm from "@/modules/finances/receipts/views/UpsertForm"
import { deleteInternalDebt, fetchInternalDebt } from "@/modules/hrs/internal_debts/services/internaldebts.services"
import { fetchReceiptCategories } from "@/modules/settings/receipt-categories/services/receipt-categories.serivces"
import { ResponseData } from "@/types"
import { formatMoney, normalizeText } from "@/utils"
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons"
import { Button, Card, Flex, Popconfirm, Space, Table, Tag, Typography } from "antd"
import { SorterResult } from "antd/es/table/interface"
import dayjs from "dayjs"
import queryString from "query-string"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AnyObject } from "yup/lib/types"

type EditableTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>

const List: React.FC = () => {
  const [dataSource, setDataSource] = useState<ResponseData<Receipt>>()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [needRefresh, setNeedRefresh] = useState(false)
  const [idEdit, setIdEdit] = useState<string>("")
  const [actionType, setActionType] = useState<string>("")
  const [receiptCategories, setReceiptCategories] = useState<object[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()
  const location = useLocation()
  const filterRef = useRef<{ filterFunction: () => void } | null>(null)
  const [defaultFilterValues, setDefaultFilterValues] = useState<{ [key: string]: any } | null>(null)
  const [filterValues, setFilterValues] = useState<{ [key: string]: string } | null>(null)
  const [sortPaginationValues, setSortPaginationValues] = useState<{ [key: string]: string } | null>(null)

  const onRefresh = () => {
    if (filterRef.current) {
      filterRef.current.filterFunction()
    }
    setSortPaginationValues(null)
  }
  const onDismissModal = () => {
    setOpen(false)
  }
  const showModal = () => {
    setActionType("CREATE")
    setOpen(true)
  }

  const showModalEdit = (id: string) => {
    navigate(`/finances/receipts/${id}${location.search}`)
    setIdEdit(id)
    setOpen(true)
    setActionType("UPDATE")
  }

  const onTableChanged = ((pagination, _, sorter: SorterResult<AnyObject>) => {
    const sortOptions: { [key: string]: string } = sorter.order
      ? { [sorter.field as string]: sorter.order === "descend" ? "desc" : "asc" }
      : {}
    const paginationOptions: { [key: string]: string } = {
      page: `${pagination.current}`,
      limit: `${pagination.pageSize}`,
    }

    if (pagination && pagination.current) {
      setCurrentPage(pagination.current)
    }

    setSortPaginationValues({ ...sortOptions, ...paginationOptions })
    const searchParams = queryString.stringify({ ...filterValues, ...sortOptions, ...paginationOptions })

    navigate(searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname)
  }) as EditableTableProps["onChange"]

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: "5%",
    },
    {
      title: "Tên hóa đơn",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Tiền",
      dataIndex: "amount",
      key: "amount",
      render: items => `${formatMoney(items)} đ`,
      sorter: true,
      sortDirections: ["descend", "ascend", null],
      sortOrder: sortPaginationValues
        ? sortPaginationValues.amount
          ? sortPaginationValues.amount === "asc"
            ? "ascend"
            : "descend"
          : null
        : null,
    },
    {
      title: "Loại phiếu hóa đơn",
      dataIndex: "receipt_categories",
      key: "receipt_categories",
      render: receipt_categories => receipt_categories.category,
    },
    {
      title: "Ghi chú",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Loại phiếu",
      dataIndex: "type",
      width: "8%",
      key: "type",
      render: (item: TypeReceipts) => {
        return <Tag color={item === TypeReceipts.INCOME ? "success" : "error"}>{MAP_TYPE_RECEIPTS[item]}</Tag>
      },
    },
    {
      title: "Thời gian diễn ra",
      dataIndex: "date_happened",
      key: "date_happened",
      render: date_happened => date_happened && dayjs(new Date(date_happened)).format("DD/MM/YYYY"),
    },
    {
      title: "Thời gian tạo",
      dataIndex: "created_at",
      key: "created_at",
      render: created_at => created_at && dayjs(new Date(created_at)).format("HH:mm DD/MM/YYYY"),
    },
    {
      title: "Thao tác",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource && dataSource?.items.length >= 1 ? (
          <Space>
            <Button ghost type="primary" shape="round" size="small" onClick={() => showModalEdit(record?.id)}>
              Sửa
            </Button>
            <Popconfirm
              title="Bạn có chắc chắn muốn xóa?"
              description={
                (record as Receipt).internal_debt_id && (
                  <div style={{ maxWidth: "18rem" }}>
                    {`Việc xoá phiếu thu/chi này đồng nghĩa với việc phiếu ứng lương #${
                      (record as Receipt).internal_debt_id
                    } cũng sẽ bị xoá`}
                  </div>
                )
              }
              onConfirm={() => handleDeleteReceipt(record as Receipt)}
            >
              <Button type="default" shape="round" danger size="small">
                Xóa
              </Button>
            </Popconfirm>
          </Space>
        ) : null,
      width: "10%",
    },
  ]

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: Receipt) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  const onSearch = useCallback(
    (values: any) => {
      let newValue = { ...values, ...sortPaginationValues }

      if (values.search) {
        newValue = { ...newValue, search: normalizeText(values.search) }
      }

      const searchParams = queryString.stringify(newValue)
      const newUrl = searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname
      navigate(newUrl)
    },
    [sortPaginationValues],
  )

  useEffect(() => {
    if (!filterValues && !sortPaginationValues) return
    const params = { ...filterValues, ...sortPaginationValues }
    setLoading(true)
    fetchReceipts(params).then((res: any) => {
      if (sortPaginationValues && sortPaginationValues.page) {
        if (sortPaginationValues.page > res.data.meta.totalPages && res.data.meta.totalPages > 0) {
          const searchLocation = queryString.parse(location.search)
          const newParams = queryString.stringify({ ...searchLocation, page: `${res.data.meta.totalPages}` })
          const newUrl = newParams.length > 0 ? `${location.pathname}?${newParams}` : location.pathname
          navigate(newUrl)
        }
      }
      setDataSource(res.data)
      setLoading(false)
    })
  }, [filterValues, sortPaginationValues, needRefresh])

  useEffect(() => {
    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: string } = {}
    const sortPaginationValues: { [key: string]: string } = {}
    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setFilterValues(filterValues)
    setSortPaginationValues(sortPaginationValues)
  }, [location.search])

  const getReceiptCategories = async () => {
    try {
      const response = await fetchReceiptCategories()
      if (response?.data.items) {
        const filteredItems = response.data.items.map((item: any) => ({
          value: item.id,
          label: item.category,
        }))
        setReceiptCategories([...filteredItems])
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  useEffect(() => {
    getReceiptCategories()
    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: any } = {}
    const sortPaginationValues: { [key: string]: string } = {}
    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (value === "true" || value === "false") {
          filterValues[key] = value === "true" ? true : false
          continue
        }
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          if (key === "page") {
            setCurrentPage(parseInt(value))
          }
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setDefaultFilterValues({ ...filterValues, ...sortPaginationValues })
  }, [])

  const handleDeleteReceipt = async (record: Receipt) => {
    await deleteReceipt(record)
    if (record.internal_debt_id) {
      const internalDebt = await fetchInternalDebt(`${record.internal_debt_id}`)
      if (internalDebt.data) {
        await deleteInternalDebt(internalDebt.data)
      }
    }
    setNeedRefresh(!needRefresh)
  }

  return (
    <div>
      <Card>
        <Flex justify="space-between">
          <Typography.Title level={4} style={styles.title}>
            Danh sách Hóa đơn
          </Typography.Title>
          <Space>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Làm mới
            </Button>
            <Button type="primary" onClick={() => showModal()} icon={<PlusOutlined />}>
              Thêm mới
            </Button>
          </Space>
        </Flex>
        <Card type="inner" style={styles.card}>
          <Filters
            filterRef={filterRef}
            defaultFilterValues={defaultFilterValues}
            onSubmit={onSearch}
            filters={
              [
                {
                  label: "Tìm kiếm tên hóa đơn",
                  placeholder: "Tên hóa đơn",
                  name: "search",
                  type: "text",
                  defaultValue: "",
                },
                {
                  label: "Lọc loại phiếu",
                  placeholder: "Vui lòng chọn loại",
                  name: "type",
                  type: "select",
                  options: [
                    { value: "", label: "Tất cả" },
                    { value: TypeReceipts.INCOME, label: "Thu" },
                    { value: TypeReceipts.EXPENSE, label: "Chi" },
                  ],
                  defaultValue: "",
                },
                {
                  label: "Lọc danh mục",
                  placeholder: "Vui lòng chọn danh mục",
                  name: "category_id",
                  type: "select-search",
                  options: receiptCategories,
                  defaultValue: "",
                },
                {
                  label: "Lọc giá tiền từ",
                  name: "min_amount",
                  type: "number",
                  defaultValue: 0,
                  typeInputNumber: true,
                },

                {
                  label: "đến",
                  name: "max_amount",
                  type: "number",
                  defaultValue: 0,
                  typeInputNumber: true,
                },
              ] as FilterComponent[]
            }
          />
        </Card>
        <UpsertForm
          openProps={open}
          cancelProps={onDismissModal}
          refreshProps={onRefresh}
          actionType={actionType}
          idEdit={idEdit}
        />
        <Table
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource?.items}
          columns={columns as ColumnTypes}
          loading={loading}
          scroll={{ y: 700 }}
          onChange={onTableChanged}
          pagination={{
            total: dataSource?.meta.totalItems,
            pageSize: dataSource?.meta.itemsPerPage,
            current: currentPage,
          }}
          rowKey={record => record.id}
        />
      </Card>
    </div>
  )
}

export default List
const styles = {
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  card: {
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
}
