import { fetchPaySheet } from "@/modules/hrs/paysheets/services/paysheet.services"
import { Paysheet } from "@/modules/hrs/paysheets/types"
import { MAP_POSITION } from "@/modules/hrs/staffs"
import { fetchSettings } from "@/modules/settings/workdays/services/settings.serivces"
import { formatDate, formatterInputNumber, getExpectedWorkDays } from "@/utils"
import { useGoBack } from "@/utils/use-go-back"
import { Button, Space } from "antd"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import "../css/index.css"
const PayCheckBill = () => {
  const { id } = useParams()
  const [dataSource, setDataSource] = useState<Paysheet>()
  const [expectedWorkDays, setExpectedWorkDays] = useState<number>(30)
  const { goBack } = useGoBack("/hrs/paysheets")
  const getWorkdays = async (date: Date | string | undefined) => {
    try {
      const response = await fetchSettings("workdays")
      let workDays = [0, 1, 2, 3, 4, 5, 6]
      const today = date ? new Date(date) : new Date()
      if (response.data.value && response.data.value.length) {
        workDays = response.data.value
      }
      const expectedWorkDays = getExpectedWorkDays(today.getMonth() + 1, today.getFullYear(), workDays)
      setExpectedWorkDays(expectedWorkDays)
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  useEffect(() => {
    if (id) {
      fetchPaySheet(id).then(res => {
        setDataSource(res.data)
        getWorkdays(res.data.paysheet_date)
      })
    }
  }, [])

  const arr = [
    {
      key: "A",
      title: "Số ngày làm việc trong tháng:",
      value: `${expectedWorkDays}`,
    },
    {
      key: "B",
      title: "Số ngày công thực tế:",
      value: `${dataSource?.total_work_days}`,
    },
    {
      key: "C",
      title: "Lương cơ bản:",
      value: `${formatterInputNumber(dataSource?.basic_income)}`,
    },
    {
      key: "D",
      title: "Tổng ứng trong tháng:",
      value: `${formatterInputNumber(dataSource?.advance_payment)}`,
    },
    {
      key: "E",
      title: "Thưởng:",
      value: `${formatterInputNumber(dataSource?.extra_income)}`,
    },
    {
      key: "F",
      title: "Phạt/Truy thu:",
      value: `${formatterInputNumber(dataSource?.negative_income)}`,
    },
    {
      key: "G",
      title: "Lương tính theo ngày công:",
      value: `${formatterInputNumber(dataSource?.total_income)}`,
    },
    {
      key: "H",
      title: "Lương thực nhận cuối tháng:",
      value: `${formatterInputNumber(dataSource?.final_income)}`,
    },
  ]

  return (
    dataSource && (
      <>
        <div id="content">
          <table className="paycheck-bill" style={styles.table}>
            <tr>
              <th style={styles.table} colSpan={3}>
                <p>Phiếu lương tháng {dataSource?.paysheet_date && formatDate(dataSource?.paysheet_date, "MM/YYYY")}</p>
                <p>Nhân viên: {dataSource?.staff?.name}</p>
                <p>Chức vụ: {dataSource.staff && MAP_POSITION[dataSource.staff.position_id]}</p>
              </th>
            </tr>
            {arr.map(item => {
              return (
                <tr key={item.key}>
                  <td style={{ width: "10%", textAlign: "center" }}>{item.key}</td>
                  <td style={{ width: "50%" }}>{item.title}</td>
                  <td style={{ width: "40%", textAlign: "right" }}>{item.value}</td>
                </tr>
              )
            })}
          </table>

          <div style={styles.notes}>
            <p>Vậy, lương thực nhận là</p>
            <p>{formatterInputNumber(dataSource?.final_income)}</p>
          </div>
        </div>
        <Space style={styles.spaceButton}>
          <Button onClick={() => goBack()}>Quay lại</Button>
          <Button onClick={() => window.print()} type="primary">
            In phiếu hóa đơn
          </Button>
        </Space>
      </>
    )
  )
}
export default PayCheckBill

const styles = {
  spaceButton: {
    display: "flex",
    justifyContent: "center",
  },
  notes: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    fontWeight: "bold",
    alignItems: "center",
    marginTop: "16px",
  },
  table: {
    width: "100%",
  },
}
