import { fetchFinanceReport } from "@/modules/finance-report/services/finance-report.services"
import { DataFinanceReport, SELECT_TIME } from "@/modules/finance-report/types"
import ApexChartPie from "@/modules/finance-report/views/Apexchart-Pie"
import { Partner } from "@/modules/partners/types"
import { formatMoney, formatOnlyDate, formatterInputNumber, notificationAction } from "@/utils"
import { Button, Card, Col, DatePicker, Form, InputNumber, Row, Select, Space, Table, Typography } from "antd"

import TableSummary from "@/modules/finance-report/views/Table-Summary"
import { NotificationEnum } from "@/utils/constants"
import dayjs from "dayjs"
import moment from "moment"
import React, { useEffect, useState } from "react"
import "../css/index.css"

type EditableTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>

const List: React.FC = () => {
  const [dataFinancesReport, setDataFinancesReport] = useState<DataFinanceReport>()
  const [form] = Form.useForm()
  const thirtyDaysAgo = moment().subtract(30, "days").format("YYYY/MM/DD")

  const [formData, setFormData] = useState({
    from: thirtyDaysAgo,
    to: moment().format("YYYY/MM/DD"),
    cash_balance_begin: 0,
    real_cash_balance_end: 0,
  })

  useEffect(() => {
    const fetchFinancesReport = async () => {
      const data = {
        ...formData,
        from: moment(formData.from).format("YYYY/MM/DD"),
        to: moment(formData.to).format("YYYY/MM/DD"),
      }
      const response = await fetchFinanceReport(data)
      if (response.statusCode === 200) {
        setDataFinancesReport(response.data)
        notificationAction(NotificationEnum.REPORTS, NotificationEnum.SUCCESS)
      }
    }

    fetchFinancesReport()
  }, [formData])

  const handleFormSubmit = () => {
    const values = form.getFieldsValue()
    const newFormData = {
      cash_balance_begin: values.cash_balance_begin ? parseFloat(values.cash_balance_begin) : 0,
      real_cash_balance_end: values.real_cash_balance_end ? parseFloat(values.real_cash_balance_end) : 0,
      from: values.from.toDate(),
      to: values.to.toDate(),
    }
    setFormData(newFormData)
  }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "#",
      dataIndex: "sub_title",
      key: "sub_title",
      width: "5%",
      align: "center",
    },
    {
      title: "Diễn giải",
      dataIndex: "name",
      key: "name",
      width: "55%",
    },
    {
      title: "Số tiền",
      dataIndex: "value",
      key: "value",
      width: "20%",
      align: "right",
      render: (value: number) => value && `${formatMoney(value)} đ`,
    },
    {
      title: "%",
      dataIndex: "percent_of_total",
      key: "percent_of_total",
      align: "right",
      width: "20%",
      render: (percent_of_total: number) => percent_of_total && `${percent_of_total}%`,
    },
  ]

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record: Partner) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  const handleValuesChange = async (changedValues: any, allValues: any) => {
    const key = Object.keys(changedValues)[0]
    const value = Object.values(changedValues)[0]

    if (key === "select_time") {
      if (value === SELECT_TIME.LAST30DAYS) {
        form.setFieldValue("from", dayjs(thirtyDaysAgo))
        form.setFieldValue("to", dayjs())
      } else if (value === SELECT_TIME.LASTMONTH) {
        form.setFieldValue("from", dayjs().subtract(1, "month").date(1))
        form.setFieldValue("to", dayjs().subtract(1, "month").endOf("month"))
      } else if (value === SELECT_TIME.THISMONTH) {
        form.setFieldValue("from", dayjs().date(1))
        form.setFieldValue("to", dayjs().endOf("month"))
      } else if (value === SELECT_TIME.THISQUARTER) {
        const currentQuarter = Math.ceil((dayjs().month() + 1) / 3)
        const firstMonthOfQuarter = currentQuarter * 3 - 3
        const lastMonthOfQuarter = currentQuarter * 3 - 1
        form.setFieldValue("from", dayjs().month(firstMonthOfQuarter).date(1))
        form.setFieldValue("to", dayjs().month(lastMonthOfQuarter).endOf("month"))
      }
    }
    return Promise.resolve()
  }

  return (
    dataFinancesReport && (
      <div>
        <Card>
          <Card type="inner" style={styles.card}>
            <Form
              name="customized_form_controls"
              style={{ justifyContent: "flex-start" }}
              form={form}
              onFinish={handleFormSubmit}
              onValuesChange={handleValuesChange}
            >
              <Row gutter={16}>
                <Col span={10}>
                  <Form.Item label="Tiền tồn đầu kì" name="cash_balance_begin" initialValue={0}>
                    <InputNumber
                      style={styles.inputNumber}
                      placeholder="Nhập tiền"
                      formatter={value => formatterInputNumber(value)}
                      addonAfter="VNĐ"
                      step={10000}
                      min={0}
                    />
                  </Form.Item>

                  <Form.Item label="Tiền tồn cuối kì thực tế" name="real_cash_balance_end" initialValue={0}>
                    <InputNumber
                      style={styles.inputNumber}
                      placeholder="Nhập tiền"
                      formatter={value => formatterInputNumber(value)}
                      addonAfter="VNĐ"
                      step={10000}
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Row>
                    <Space>
                      <Form.Item
                        label="Chọn thời gian xem báo cáo từ"
                        name="from"
                        initialValue={dayjs(new Date()).subtract(30, "days")}
                      >
                        <DatePicker key="from" format="DD/MM/YYYY" />
                      </Form.Item>
                      <Form.Item label="Đến" name="to" initialValue={dayjs(new Date())}>
                        <DatePicker key="to" format="DD/MM/YYYY" />
                      </Form.Item>
                    </Space>
                  </Row>
                  <Form.Item label="Chọn khoảng thời gian" name="select_time">
                    <Select
                      placeholder="Chọn thời gian"
                      optionFilterProp="children"
                      options={[
                        { value: SELECT_TIME.LAST30DAYS, label: "30 ngày gần nhất" },
                        { value: SELECT_TIME.LASTMONTH, label: "Tháng trước" },
                        { value: SELECT_TIME.THISMONTH, label: "Tháng này" },
                        { value: SELECT_TIME.THISQUARTER, label: "Quý này" },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Form.Item>
                    <Button type="primary" onClick={handleFormSubmit} style={styles.button}>
                      Xem báo cáo
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button type="default" htmlType="reset" style={styles.button}>
                      Làm mới
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>

          <Typography.Title level={2} style={{ textAlign: "center", fontWeight: "bold" }}>
            Báo cáo kinh doanh {formatOnlyDate(formData.from)} - {formatOnlyDate(formData.to)}
          </Typography.Title>

          <Table
            rowClassName={record =>
              record.type === "header" ? "header-row" : record.type === "sub_header" ? "sub-header-row" : "children-row"
            }
            bordered
            dataSource={dataFinancesReport.table ?? []}
            columns={columns as ColumnTypes}
            pagination={false}
            rowKey={record => record.name}
            className="table-finance-report"
          />
        </Card>

        <Row style={styles.row}>
          <Col span={12}>
            <Typography.Title level={4} style={{ textAlign: "center", fontWeight: "bold" }}>
              Bảng thống kê lợi nhuận
            </Typography.Title>
            <TableSummary data={dataFinancesReport.profitTable} />
          </Col>
          <Col span={12}>
            <ApexChartPie
              dataLabels={dataFinancesReport.chart.incomeExpense.labels}
              dataSeries={dataFinancesReport.chart.incomeExpense.series}
              title="Biểu đồ lợi nhuận - Chi phí"
            />
          </Col>
        </Row>
        <Row style={styles.row}>
          <Col span={12}>
            <ApexChartPie
              dataLabels={dataFinancesReport.chart.operatingExpense.labels}
              dataSeries={dataFinancesReport.chart.operatingExpense.series}
              title="Phân bố chi phí nhà hàng"
            />
          </Col>
          <Col span={12}>
            <ApexChartPie
              dataLabels={dataFinancesReport.chart.otherExpense.labels}
              dataSeries={dataFinancesReport.chart.otherExpense.series}
              title="Phân bố chi phí quản lí nhà hàng"
            />
          </Col>
        </Row>
      </div>
    )
  )
}

export default List

const styles = {
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  card: {
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
  inputNumber: {
    width: "100%",
  },

  row: { display: "flex", alignItems: "center" },
  button: { width: "100%" },
}
