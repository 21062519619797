import { CurrencyTypes } from "@/modules/finances/currency/types"
import { formatMoney } from "@/utils/helpers"
import { Form, InputNumber } from "antd"
import React, { useEffect, useState } from "react"

interface Props {
  name: string
  isReset: boolean
  history: { [key: string]: number }
}

export const MoneyFormItem: React.FC<Props> = ({ name, isReset, history }) => {
  const [value, setValue] = useState<number>(0)
  const [money, setMoney] = useState<string>("")

  useEffect(() => {
    setMoney(formatMoney(value * CurrencyTypes[name as keyof typeof CurrencyTypes]))
  }, [value])

  useEffect(() => {
    setValue(0)
    setMoney("0")
  }, [isReset])

  useEffect(() => {
    const value = history[name] as number
    if (value) {
      setMoney(formatMoney(value * CurrencyTypes[name as keyof typeof CurrencyTypes]))
    }
  }, [history])
  return (
    <Form.Item name={name}>
      <InputNumber
        min={0}
        placeholder="Điền số tờ"
        style={styles.input}
        addonBefore={
          <span style={styles.spanBefore}>{formatMoney(CurrencyTypes[name as keyof typeof CurrencyTypes])} VNĐ</span>
        }
        addonAfter={<span style={styles.spanAfter}>{money} VNĐ</span>}
        onChange={e => setValue(e as number)}
      />
    </Form.Item>
  )
}

const styles = {
  input: {
    width: 500,
    justifyContent: "center",
  },
  spanBefore: {
    width: 100,
    display: "inline-block",
  },
  spanAfter: {
    width: 200,
    display: "inline-block",
  },
}
