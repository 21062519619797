import List from "@/modules/hrs/paysheets/views/List"
import { Upsert } from "@/modules/hrs/paysheets/views/Upsert"
import PayCheckBill from "@/modules/hrs/paysheets/views/paycheck-bill"
import { Route, Routes } from "react-router-dom"

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      {/* <Route path="create" element={<Create />} /> */}
      <Route path=":id" element={<Upsert />} />
      <Route path="paycheck_bill/:id" element={<PayCheckBill />} />
    </Routes>
  )
}
