import { InternalDebt } from "@/modules/hrs/internal_debts/types"
import { Http } from "@/network/http"
import { Filters, NotificationEnum, StatusEnum } from "@/utils/constants"
import { notificationAction } from "@/utils/helpers"

export const API_PREFIX_PATH = "/internal-debts"

export const fetchInternalDebts = async (filters?: Filters) => {
  return await Http.get(`${API_PREFIX_PATH}`, { params: filters })
}

export const fetchInternalDebt = async (id: string) => {
  return await Http.get(`${API_PREFIX_PATH}/${id}`)
}

export const createInternalDebt = async (data?: InternalDebt) => {
  return await Http.post(`${API_PREFIX_PATH}`, data)
}

export const updateInternalDebt = async (id: string, data?: InternalDebt) => {
  return await Http.put(`${API_PREFIX_PATH}/${id}`, data)
}

export const deleteInternalDebt = async (data: InternalDebt) => {
  try {
    const updatedData = { ...data }
    if (!updatedData.id) return
    const response = await updateInternalDebt(updatedData.id, { ...updatedData, status: StatusEnum.DELETED })
    if (response.statusCode === 200) {
      notificationAction(NotificationEnum.DELETED, NotificationEnum.SUCCESS)
    }
  } catch (error) {
    console.error("Addition failed:", error)
    notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
  }
}
