import { CategoryRouter } from "@/modules/settings/receipt-categories"
import { EditPopup } from "@/modules/settings/receipt-categories/views/EditPopup"
import { WorkDaysRouter } from "@/modules/settings/workdays"
import { Route, Routes } from "react-router-dom"

export const SettingsRouter = () => {
  return (
    <Routes>
      <Route path="categories/*" element={<CategoryRouter />} />
      <Route path="categories/:id" element={<EditPopup />} />
      <Route path="workdays/*" element={<WorkDaysRouter />} />
    </Routes>
  )
}
