import { ProfitTableType } from "@/modules/finance-report/types"
import { formatMoney } from "@/utils"
import "../css/index.css"
const TableSummary = ({ data }: { data: ProfitTableType[] }) => {
  return (
    <div style={styles.div}>
      <table className="table-summary" style={styles.table}>
        <tbody>
          {data &&
            data.map(item => (
              <tr key={item.name}>
                <td style={{ background: "rgb(164 194 244)" }}>{item.name}</td>
                <td style={{ textAlign: "right", background: "rgb(201 218 248)" }}>{formatMoney(item.value)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableSummary

const styles = {
  div: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  table: {
    width: "100%",
  },
}
