export enum WorkDaysEnum {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export const MAP_WORK_DAYS = [
  {
    name: "Thứ Hai",
    value: WorkDaysEnum.MONDAY,
  },
  {
    name: "Thứ Ba",
    value: WorkDaysEnum.TUESDAY,
  },
  {
    name: "Thứ Tư",
    value: WorkDaysEnum.WEDNESDAY,
  },
  {
    name: "Thứ Năm",
    value: WorkDaysEnum.THURSDAY,
  },
  {
    name: "Thứ Sáu",
    value: WorkDaysEnum.FRIDAY,
  },
  {
    name: "Thứ Bảy",
    value: WorkDaysEnum.SATURDAY,
  },
  {
    name: "Chủ Nhật",
    value: WorkDaysEnum.SUNDAY,
  },
]

export interface WorkDayItem {
  name: string
  value: string
}
