import { PathItem } from "@/types"

export const DASHBOARD: PathItem = {
  DETAIL: { PATH: "/", LABEL: "DashBoard", BREADCRUMB: ["DashBoard"] },
}

export const PROFILE: PathItem = {
  DETAIL: { PATH: "/profile", LABEL: "Thông tin cá nhân", BREADCRUMB: ["Thông tin cá nhân"] },
  EDIT: { PATH: "/profile/edit", LABEL: "", BREADCRUMB: [] },
}

export const USER: PathItem = {
  LIST: { PATH: "/manages/users", LABEL: "Quản lí người dùng", BREADCRUMB: ["Quản lí người dùng"] },
  CREATE: { PATH: "/manages/users/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/manages/users/:id", LABEL: "", BREADCRUMB: [] },
}

export const ROLE: PathItem = {
  LIST: { PATH: "/manages/roles", LABEL: "Quản lí vai trò", BREADCRUMB: ["Quản lí vai trò"] },
  CREATE: { PATH: "/manages/roles/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/manages/roles/:id", LABEL: "", BREADCRUMB: [] },
}

export const PERMISSION: PathItem = {
  LIST: { PATH: "/manages/permissions", LABEL: "Quản lí quyền hạn", BREADCRUMB: ["Quản lí quyền hạn"] },
  CREATE: { PATH: "/manages/permissions/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/manages/permissions/:id", LABEL: "", BREADCRUMB: [] },
}

export const PUBLIC: PathItem = {
  LOGIN: { PATH: "/auth/login", LABEL: "Đăng nhập", BREADCRUMB: ["Đăng nhập"] },
  FORGOT_PASSWORD: { PATH: "/auth/forgot-password", LABEL: "", BREADCRUMB: [] },
  RESET_PASSWORD: { PATH: "/auth/reset-password", LABEL: "", BREADCRUMB: [] },
}

export const PARTNER: PathItem = {
  LIST: { PATH: "/settings/partners", LABEL: "Đối tác", BREADCRUMB: ["Đối tác"] },
  CREATE: { PATH: "/settings/partners/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/settings/partners/:id", LABEL: "", BREADCRUMB: [] },
}

export const CATEGORY: PathItem = {
  LIST: { PATH: "/settings/categories", LABEL: "Danh mục thu chi", BREADCRUMB: ["Danh mục thu chi"] },
  CREATE: { PATH: "/settings/categories/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/settings/categories/edit", LABEL: "", BREADCRUMB: [] },
}

export const STAFF: PathItem = {
  LIST: { PATH: "/hrs/staffs", LABEL: "Quản lí nhân viên", BREADCRUMB: ["Quản lí nhân viên"] },
  CREATE: { PATH: "/hrs/staffs/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/hrs/staffs/edit", LABEL: "", BREADCRUMB: [] },
}
export const RECEIPT: PathItem = {
  LIST: { PATH: "/finances/receipts", LABEL: "Danh sách thu chi", BREADCRUMB: ["Danh sách thu chi"] },
  CREATE: { PATH: "/finances/receipts/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/finances/receipts/edit", LABEL: "", BREADCRUMB: [] },
}
export const PAYABLEDEBTS: PathItem = {
  LIST: { PATH: "/finances/payable-debts", LABEL: "Công nợ phải trả", BREADCRUMB: ["Danh mục"] },
  CREATE: { PATH: "/finances/payable-debts/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/finances/payable-debts/edit", LABEL: "", BREADCRUMB: [] },
}

export const PROPERTY: PathItem = {
  LIST: { PATH: "/finances/properties", LABEL: "Kiểm kê tài sản/hàng hoá", BREADCRUMB: ["Kiểm kê tài sản/hàng hoá"] },
  CREATE: { PATH: "/finances/properties/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/finances/properties/edit", LABEL: "", BREADCRUMB: [] },
}

export const RECEIVABLEDEBTS: PathItem = {
  LIST: { PATH: "/finances/receivable-debts", LABEL: "Công nợ phải thu", BREADCRUMB: ["Danh mục"] },
  CREATE: { PATH: "/finances/receivable-debts/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/finances/receivable-debts/edit", LABEL: "", BREADCRUMB: [] },
}
export const INTERNALDEBTS: PathItem = {
  LIST: { PATH: "/hrs/internal-debts", LABEL: "Ứng lương", BREADCRUMB: ["Ứng lương"] },
  CREATE: { PATH: "/hrs/internal-debts/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/hrs/internal-debts/edit", LABEL: "", BREADCRUMB: [] },
}
export const CUSTOMERS: PathItem = {
  LIST: { PATH: "/customers", LABEL: "Khách hàng", BREADCRUMB: ["Khách hàng"] },
  CREATE: { PATH: "/customers/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/customers/edit", LABEL: "", BREADCRUMB: [] },
}
export const PAYSHEET: PathItem = {
  LIST: { PATH: "/hrs/paysheets", LABEL: "Bảng lương", BREADCRUMB: ["Bảng lương"] },
  CREATE: { PATH: "/hrs/paysheets/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/hrs/paysheets/edit", LABEL: "", BREADCRUMB: [] },
}

export const WORKDAYS: PathItem = {
  LIST: { PATH: "/settings/workdays", LABEL: "Lịch làm việc", BREADCRUMB: ["Lịch làm việc"] },
  CREATE: { PATH: "/settings/workdays/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/settings/workdays/edit", LABEL: "", BREADCRUMB: [] },
}
export const CURRENCY: PathItem = {
  LIST: { PATH: "/finances/currency", LABEL: "Bảng đếm tiền", BREADCRUMB: ["Bảng đếm tiền"] },
  CREATE: { PATH: "/finances/currency/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/finances/currency/edit", LABEL: "", BREADCRUMB: [] },
}

export const CHECKIN: PathItem = {
  LIST: { PATH: "/hrs/checkin", LABEL: "Chấm công", BREADCRUMB: ["Chấm công"] },
  CREATE: { PATH: "/hrs/checkin/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/hrs/checkin/edit", LABEL: "", BREADCRUMB: [] },
}
