export declare type ACTION_DEFINE =
  | "SUCCESS"
  | "FAILED"
  | "GET_LIST"
  | "GET_LIST_SUCCESS"
  | "GET_LIST_FAILED"
  | "SET_LOADING"
  | "DELETE_ONE"
  | "DELETE_MANY"
  | "ARCHIVE_MANY"
  | "UNARCHIVE_MANY"
  | "GET_DETAIL"
  | "GET_DETAIL_SUCCESS"
  | "GET_DETAIL_FAILED"
  | "CREATE"
  | "CREATE_SUCCESS"
  | "CREATE_FAILED"
  | "UPDATE"
  | "UPDATE_SUCCESS"
  | "UPDATE_FAILED"

const ACTION_TYPES = {
  SET_LOADING: {},
  SUCCESS: {},
  FAILED: {},

  GET_LIST: {},
  GET_LIST_SUCCESS: {},
  GET_LIST_FAILED: {},

  DELETE_ONE: {},
  DELETE_MANY: {},

  ARCHIVE_MANY: {},
  UNARCHIVE_MANY: {},

  GET_DETAIL: {},
  GET_DETAIL_SUCCESS: {},
  GET_DETAIL_FAILED: {},

  CREATE: {},
  CREATE_SUCCESS: {},
  CREATE_FAILED: {},

  UPDATE: {},
  UPDATE_SUCCESS: {},
  UPDATE_FAILED: {},
}

export { ACTION_TYPES }

export enum StatusEnum {
  ACTIVE = "active",
  DISABLED = "disabled",
  DELETED = "deleted",
  CHECKIN = "checkin",
  UNCHECKIN = "uncheckin",
}

export const MAP_STATUS = {
  [StatusEnum.ACTIVE]: "Hoạt động",
  [StatusEnum.DISABLED]: "Không hoạt động",
  [StatusEnum.DELETED]: "Đã xóa",
  [StatusEnum.CHECKIN]: "Đã chấm công",
  [StatusEnum.UNCHECKIN]: "Chưa chấm công",
}

export enum NotificationEnum {
  SUCCESS = "success",
  ERROR = "error",
  DELETED = "Đã xoá thành công!",
  UPDATED = "Đã cập nhật thành công!",
  CREATED = "Đã thêm mới thành công!",
  REPORTS = "Tạo báo cáo mới thành công!",
  SETTLING = "Đã thanh toán thành công!",
}

export interface Filters {
  [key: string]: any
}
