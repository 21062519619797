import {
  createPermission,
  fetchPermission,
  updatePermission,
} from "@/modules/manages/permissions/services/permissions.services"
import { notificationAction } from "@/utils"
import { NotificationEnum } from "@/utils/constants"
import { Button, Form, Input, Modal, Space } from "antd"
import { Rule } from "antd/es/form"
import { useEffect, useState } from "react"

interface BaseUpsertForm {
  openProps?: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: "CREATE" | "UPDATE"
  idEdit?: string
}

const UpsertForm: React.FC<BaseUpsertForm> = props => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const onReset = () => {
    form.resetFields()
  }

  useEffect(() => {
    if (props.actionType === "CREATE") {
      form.resetFields()
    } else {
      if (props.idEdit) {
        fetchPermission(props.idEdit).then(res => {
          if (res.data) {
            form.setFieldsValue({
              name: res.data.name,
              notes: res.data.notes,
            })
          }
        })
      }
    }
  }, [props.actionType])

  const handleAddPermission = async () => {
    try {
      const values = await form.validateFields()
      const newData: { route: string; route_name: string } = {
        route: values.route,
        route_name: values.route_name,
      }
      setLoading(true)
      const response = await createPermission(newData)

      if (response.statusCode === 200) {
        setLoading(false)
        onReset()
        notificationAction(NotificationEnum.CREATED, NotificationEnum.SUCCESS)
        props.cancelProps()
        props.refreshProps()
      } else {
        setLoading(false)
        form.setFields([
          {
            name: "route",
            errors: [response.message],
          },
        ])
      }
    } catch (error: any) {
      setLoading(false)
      form.setFields([
        {
          name: "route",
          errors: [error.message],
        },
      ])
      console.error("Addition failed:", error)
    }
  }

  const handleUpdatePermission = async () => {
    try {
      if (!props.idEdit) return
      const values = await form.validateFields()
      setLoading(true)

      const response = await updatePermission(props.idEdit, { name: values.name, notes: values.notes })
      if (response.statusCode === 200) {
        setLoading(false)
        onReset()
        notificationAction(NotificationEnum.UPDATED, NotificationEnum.SUCCESS)
        props.cancelProps()
        props.refreshProps()
      }
    } catch (e) {
      console.error(e)
    }
  }

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button
            key="cancel"
            onClick={() => {
              props.cancelProps()
              onReset()
            }}
          >
            Hủy
          </Button>
          {props.actionType === "CREATE" && (
            <Button type="primary" onClick={() => handleAddPermission()} loading={loading}>
              Thêm mới
            </Button>
          )}
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={() => handleUpdatePermission()} loading={loading}>
              Cập nhật
            </Button>
          )}
        </Space>
      </>
    )
  }
  return (
    <Modal
      title={props.actionType === "CREATE" ? "Thêm quyền hạn" : "Chỉnh sửa quyền hạn"}
      open={props.openProps}
      width={600}
      onCancel={props.cancelProps}
      footer={renderFooter()}
      forceRender
    >
      <Form
        name="wrap"
        labelCol={{ flex: "160px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        style={{ maxWidth: 600 }}
        form={form}
      >
        {getFormContent(props.actionType ?? "CREATE").map((item: FormItem) => (
          <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules} extra={item.extra}>
            <Input placeholder={item.inputPlaceholder} />
          </Form.Item>
        ))}
      </Form>
    </Modal>
  )
}

interface FormItem {
  label: string
  name: string
  rules?: Rule[]
  extra?: string
  inputPlaceholder: string
}
const getFormContent = (type: "CREATE" | "UPDATE"): FormItem[] => {
  if (type === "CREATE") {
    return [
      {
        label: "Đuòng dẫn",
        name: "route",
        rules: [{ required: true, message: "Vui lòng nhập dữ liệu vào!" }],
        extra: "Nhập đường dẫn của API muốn tạo quyền. Ví dụ: /staffs, /paysheets...",
        inputPlaceholder: "Nhập đường dẫn để tạo quyền",
      },
      {
        label: "Tên của đường dẫn",
        name: "route_name",
        rules: [{ required: true, message: "Vui lòng nhập dữ liệu vào!" }],
        extra: "Nhập tên của đường dẫn API muốn tạo quyền. Ví dụ: Nhân viên, Bảng lương...",
        inputPlaceholder: "Nhập tên đường dẫn của quyền muốn tạo",
      },
    ]
  }

  return [
    {
      label: "Tên quyền hạn",
      name: "name",
      rules: [{ required: true, message: "Vui lòng nhập dữ liệu vào!" }],
      inputPlaceholder: "Nhập tên quyền hạn",
    },
    {
      label: "Ghi chú",
      name: "notes",
      inputPlaceholder: "Nhập ghi chú của quyền hạn",
    },
  ]
}
export default UpsertForm
