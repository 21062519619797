import { TypeReceipts } from "@/modules/finances/receipts/types"
import { ApexOptions } from "apexcharts"

const getNearest12Months = () => {
  const res: string[] = []
  const date = new Date()
  date.setDate(1)
  res.unshift(
    `${date.getMonth() + 1 >= 10 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`}/${date.getFullYear()}`,
  )
  for (let i = 0; i < 11; i++) {
    date.setMonth(date.getMonth() - 1)
    res.unshift(
      `${date.getMonth() + 1 >= 10 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`}/${date.getFullYear()}`,
    )
  }
  return res
}

export enum TitleChart {
  staffs = "staffs",
  receipts = "receipts",
  debts = "debts",
}
export const MAP_TitleChart = {
  [TitleChart.staffs]: "Nhân viên",
  [TitleChart.receipts]: "Thu chi",
  [TitleChart.debts]: "Công nợ",
}

export const lineChartOption: ApexOptions = {
  chart: {
    width: "100%",
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },

  legend: {
    show: false,
  },

  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 0,
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: ["#8c8c8c"],
      },
    },
  },

  xaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: "#8c8c8c",
      },
    },
    categories: getNearest12Months(),
  },

  tooltip: {
    y: {
      formatter: function (val: any) {
        const value = val.toLocaleString("it-IT", { style: "currency", currency: "VND" })
        return value
      },
    },
  },
}

export interface StatisticData {
  income_expense: IncomeExpenseStatistic
  staffs: StaffStatisticType
  payable_debts: PayableDebts
  receivable_debts: ReceivableDebts
}

export interface IncomeExpenseStatistic {
  income: IncomeExpense[]
  expense: IncomeExpense[]
  chart: Chart
}

export interface IncomeExpense {
  name: string
  value: number
}

export interface Chart {
  income: ChartType[]
  expense: ChartType[]
}

export interface ChartType {
  receipts_type: string
  month: string
  value: number
}

export interface StaffStatisticType {
  count: number
  basic_income: number
  final_income: number
  chart: ChartStaff[]
}

export interface ChartStaff {
  month: string
  income: number
}

export type PayableDebts = {
  total_payable_debts: number
  chart: DebtsChart[]
}

export interface DebtsChart {
  month: string
  amount: number
}

export type ReceivableDebts = {
  total_receivable_debts: number
  chart: DebtsChart[]
}

export interface DataStaticType {
  name: string
  data: number[]
  dataDetail?: any[]
  offsetY: number
  color: string
  total?: number
  suffix: string
  hidden?: boolean
}

export interface StatisticCard {
  name: string
  value: number
  prefix?: string
  suffix?: string
}

export const STATISTIC_DATES = {
  today: "hôm nay",
  thisWeek: "tuần này",
  thisMonth: "tháng này",
  lastWeek: "tuần trước",
}

export const STATISTIC_RECEIPTS = {
  [TypeReceipts.EXPENSE]: "Chi tiêu",
  [TypeReceipts.INCOME]: "Doanh thu",
}
