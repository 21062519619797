import {
  createReceiptCategories,
  fetchReceiptCategory,
  updateReceiptCategories,
} from "@/modules/settings/receipt-categories/services/receipt-categories.serivces"
import {
  ClassifyReceiptCategoriesType,
  ReceiptCategory,
  TypeReceiptEnum,
} from "@/modules/settings/receipt-categories/types"
import { NotificationEnum, StatusEnum } from "@/utils/constants"
import { filterOption, notificationAction, notificationCreateSuccess, notificationUpdateSuccess } from "@/utils/helpers"
import { Button, Form, Input, Modal, Select, Space } from "antd"
import { useEffect, useState } from "react"

interface UpsertFormProps {
  openProps: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: string
  idEdit?: string
}

const UpsertForm: React.FC<UpsertFormProps> = props => {
  const [form] = Form.useForm()
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const onReset = () => {
    form.resetFields()
  }

  const handleCompleteForm = () => {
    onReset()
    props.refreshProps()
    props.cancelProps()
    props.idEdit ? notificationUpdateSuccess() : notificationCreateSuccess()
    setButtonLoading(false)
  }

  useEffect(() => {
    if (props.actionType === "CREATE") {
      form.resetFields()
    }
  }, [props.actionType])

  const handleAddReceiptCategory = async () => {
    try {
      const values = await form.validateFields()
      const newData: ReceiptCategory = {
        ...values,
      }
      setButtonLoading(true)
      const response = await createReceiptCategories(newData)

      if (response.statusCode === 200) {
        handleCompleteForm()
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Addition failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  useEffect(() => {
    const onInitEdit = async (idEdit: string) => {
      const receiptCategory = await fetchReceiptCategory(idEdit)
      setTypeReceipt(receiptCategory.data.type)

      form.setFieldsValue({
        category: receiptCategory.data.category,
        type: receiptCategory.data.type,
        status: receiptCategory.data.status,
        extra_type: receiptCategory.data.extra_type,
      })
    }

    if (props.idEdit) {
      onInitEdit(props.idEdit)
    }
  }, [props.idEdit])

  const handleUpdateReceiptCategory = async () => {
    try {
      const values = await form.validateFields()
      const editData = {
        ...values,
      }
      if (props.idEdit) {
        setButtonLoading(true)
        const response = await updateReceiptCategories(props.idEdit, editData)
        if (response.statusCode === 200) {
          handleCompleteForm()
        }
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Edit failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }
  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button
            key="cancel"
            onClick={() => {
              props.cancelProps()
              onReset()
            }}
          >
            Hủy
          </Button>
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={handleUpdateReceiptCategory} loading={buttonLoading}>
              Lưu
            </Button>
          )}
          {props.actionType === "CREATE" && (
            <Button type="primary" onClick={() => handleAddReceiptCategory()} loading={buttonLoading}>
              Thêm mới
            </Button>
          )}
        </Space>
      </>
    )
  }

  const [optionSelect, setOptionSelect] = useState<{ value: string; label: string }[]>([])
  const [typeReceipt, setTypeReceipt] = useState("")

  const getTypeCategories = async () => {
    if (typeReceipt === TypeReceiptEnum.INCOME) {
      setOptionSelect([
        { value: ClassifyReceiptCategoriesType.OPERATING, label: "Doanh thu bán hàng" },
        { value: ClassifyReceiptCategoriesType.OTHER, label: "Doanh thu từ hoạt động khác" },
      ])
    } else if (typeReceipt === TypeReceiptEnum.EXPENSE) {
      setOptionSelect([
        { value: ClassifyReceiptCategoriesType.OPERATING, label: "Chi phí nhà hàng" },
        { value: ClassifyReceiptCategoriesType.OTHER, label: "Chi phí quản lí nhà hàng" },
      ])
    }
  }

  useEffect(() => {
    setOptionSelect([])
    form.setFieldValue("extra_type", null)
    getTypeCategories()
  }, [typeReceipt])

  return (
    <>
      <Modal
        title={props.actionType === "CREATE" ? "Thêm danh mục thu/chi" : "Chỉnh sửa danh mục thu/chi"}
        open={props.openProps}
        width={600}
        onCancel={props.cancelProps}
        footer={renderFooter()}
      >
        <Form
          name="wrap"
          labelCol={{ flex: "120px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          form={form}
        >
          <Form.Item
            label="Tên danh mục:"
            name="category"
            rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
          >
            <Input placeholder="Nhập tên danh mục" />
          </Form.Item>

          <Form.Item
            label="Loại danh mục"
            name="type"
            rules={[{ required: true, message: "Vui lòng chọn loại danh mục!" }]}
          >
            <Select
              placeholder="Vui lòng chọn loại danh mục"
              style={{ width: "100%" }}
              options={[
                { value: TypeReceiptEnum.EXPENSE, label: "Danh mục chi" },
                { value: TypeReceiptEnum.INCOME, label: "Danh mục thu" },
              ]}
              onChange={value => setTypeReceipt(value)}
            />
          </Form.Item>
          <Form.Item label="Trạng thái" name="status" initialValue={StatusEnum.ACTIVE}>
            <Select
              placeholder="Vui lòng chọn trạng thái"
              style={{ width: "100%" }}
              options={[
                { value: StatusEnum.ACTIVE, label: "Hoạt động" },
                { value: StatusEnum.DISABLED, label: "Không hoạt động" },
              ]}
              defaultValue={StatusEnum.ACTIVE}
            />
          </Form.Item>
          <Form.Item
            label="Loại danh mục phụ"
            name="extra_type"
            rules={[{ required: true, message: "Vui lòng chọn loại danh mục phụ!" }]}
          >
            <Select
              placeholder="Vui lòng chọn dạnh mục phụ"
              filterOption={filterOption}
              optionFilterProp="children"
              options={optionSelect}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default UpsertForm
