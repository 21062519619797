import { createMassCheckIn } from "@/modules/hrs/check_ins/services/checkIn.services"
import { dateFormatListMonth, notificationAction } from "@/utils"
import { NotificationEnum } from "@/utils/constants"
import { Button, DatePicker, Form, Modal, Space } from "antd"
import dayjs from "dayjs"
import { useState } from "react"

interface Props {
  openProps: boolean
  cancelProps: () => void
  refreshProps: () => void
}
const ModalCreateMass: React.FC<Props> = props => {
  const [form] = Form.useForm()
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const onReset = () => {
    form.resetFields()
  }

  const handleCreateMass = async () => {
    try {
      const values = await form.validateFields()
      if (!values.month) return
      const date = new Date(values.month.toDate())
      date.setDate(15)
      setButtonLoading(true)
      const response = await createMassCheckIn(date)
      if (response.statusCode === 200) {
        onReset()
        props.refreshProps()
        props.cancelProps()
        notificationAction(NotificationEnum.CREATED, NotificationEnum.SUCCESS)
        setButtonLoading(false)
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Addition failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button
            key="cancel"
            onClick={() => {
              props.cancelProps()
              onReset()
            }}
          >
            Hủy
          </Button>
          <Button type="primary" onClick={() => handleCreateMass()} loading={buttonLoading}>
            Thêm mới
          </Button>
        </Space>
      </>
    )
  }
  return (
    <Modal
      title={"Thêm mới cho tất cả nhân viên"}
      open={props.openProps}
      width={600}
      onCancel={props.cancelProps}
      footer={renderFooter()}
      forceRender
    >
      <Form name="wrap" labelAlign="left" labelWrap wrapperCol={{ flex: 1 }} colon={false} form={form}>
        <Form.Item label="Tháng tạo bảng chấm công:" name="month" initialValue={dayjs(new Date())}>
          <DatePicker picker="month" format={dateFormatListMonth} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalCreateMass
